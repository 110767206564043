import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';

class Contact extends Component {
    render() {
        return (
            <div>
                <Header />

                <Helmet>
                    <title>Contact Page: #OpenToWorkRemote</title>
                    <meta name="description" content="Get in touch with me to discuss adding, removing, or featuring a job on #OpenToWorkRemote. I'm here to help." />
                    <link rel="canonical" href="https://www.opentoworkremote.com/contact" />
                </Helmet>

                <div className="page-content bg-white">

                    <div className="dez-bnr-inr bg-dark" style={{height: "100px"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <h1 className="text-white" style={{margin: "25px"}}>Welcome! Let's Connect</h1>
                            </div>
                        </div>
                    </div>

                    <div className="content-area">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-3 col-md-3 m-3">
                                    <div className="blog-post blog-single blog-style-1">
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-6 m-6">

                                    <div className="blog-post blog-single blog-style-1">
                                        <div className="dez-post-title">
                                            <br /><h2 className="post-title m-t0 text-center">Hey, There! <span role="img" aria-label="happy person saying hi">😃 👋</span> I'm Mauro.</h2>
                                        </div>
                                        <div className="text-center m-t20"> 
                                            <img src={require("./../../images/blog/default/mnb2.png")} alt="Hi, I'm Mauro" />
                                        </div>
                                        <div className="dez-post-text">
                                            <h4>You can find me in any of the following channels:</h4>
                                            <p>Talk to me on <a href="https://www.linkedin.com/in/mauro-bonfietti/" target="_blank" rel="noopener noreferrer"><b>LinkedIn</b></a>, or <a href="https://twitter.com/maurobonfietti" target="_blank" rel="noopener noreferrer"><b>X</b></a>.</p>
                                            
                                            <p>Check it out my <Link to={"/blog"} ><b>Blog</b></Link> and more links <a href="https://linktr.ee/maurobonfietti" target="_blank" rel="noopener noreferrer"><b>About Me</b></a>.</p>
                                            <p>
                                                <span role="img" aria-label="Happy Face">😃</span> <b> All the best, Mauro. </b> <span role="img" aria-label="Waving Hand">👋️</span>
                                            </p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default Contact;
