import React, {Component} from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Browsejobgrid from './Pages/Browsejobgrid';
import Error404 from './Pages/Error404';
import PostJob from './Pages/PostJob';
import GoToApply from './Pages/GoToApply';
import ThankYou from './Pages/ThankYou';
import JobDescription from './Pages/JobDescription';
import Sponsors from './Pages/Sponsors';
import Contact from './Pages/Contact';
import UnlockJobsPage from './Pages/UnlockJobsPage';

import Blog from './Pages/Blog/Blog';
import BlogFirstPost from './Pages/Blog/BlogFirstPost';
import BlogPostBuildingTools from './Pages/Blog/BlogPostBuildingTools';
import BlogPostEmbracing from './Pages/Blog/BlogPostEmbracing';

import RemoteJobsSales from './Pages/JobsByTitle/RemoteJobsSales';
import RemoteJobsMarketing from './Pages/JobsByTitle/RemoteJobsMarketing';
import RemoteJobsBackend from './Pages/JobsByTitle/RemoteJobsBackend';
import RemoteJobsFrontend from './Pages/JobsByTitle/RemoteJobsFrontend';
import RemoteJobsFullStack from './Pages/JobsByTitle/RemoteJobsFullStack';
import RemoteJobsDevOps from './Pages/JobsByTitle/RemoteJobsDevOps';
import RemoteJobsCustomerSuccess from './Pages/JobsByTitle/RemoteJobsCustomerSuccess';
import RemoteJobsPhp from './Pages/JobsByTitle/RemoteJobsPhp';
import RemoteJobsPython from './Pages/JobsByTitle/RemoteJobsPython';
import RemoteJobsHumanResource from './Pages/JobsByTitle/RemoteJobsHumanResource';
import RemoteJobsDataEngineer from './Pages/JobsByTitle/RemoteJobsDataEngineer';
import RemoteJobsQa from './Pages/JobsByTitle/RemoteJobsQa';
import RemoteJobsSre from './Pages/JobsByTitle/RemoteJobsSre';
import RemoteJobsAccountant from './Pages/JobsByTitle/RemoteJobsAccountant';
import RemoteJobsAws from './Pages/JobsByTitle/RemoteJobsAws';
import RemoteJobsSoftwareEngineer from './Pages/JobsByTitle/RemoteJobsSoftwareEngineer';

import JobsLaravel from './Pages/JobsByTitle/JobsLaravel';
import JobsDjango from './Pages/JobsByTitle/JobsDjango';
import JobsGolang from './Pages/JobsByTitle/JobsGolang';
import JobsAws from './Pages/JobsByTitle/JobsAws';
import JobsJavaScript from './Pages/JobsByTitle/JobsJavaScript';
import JobsPhp from './Pages/JobsByTitle/JobsPhp';
import JobsBackend from './Pages/JobsByTitle/JobsBackend';
import JobsFrontend from './Pages/JobsByTitle/JobsFrontend';
import JobsFullStack from './Pages/JobsByTitle/JobsFullStack';
import JobsAccountExecutive from './Pages/JobsByTitle/JobsAccountExecutive';
import JobsCustomerSuccess from './Pages/JobsByTitle/JobsCustomerSuccess';
import JobsPython from './Pages/JobsByTitle/JobsPython';
import JobsMarketing from './Pages/JobsByTitle/JobsMarketing';
import JobsRuby from './Pages/JobsByTitle/JobsRuby';
import JobsReact from './Pages/JobsByTitle/JobsReact';
import JobsSoftwareEngineer from './Pages/JobsByTitle/JobsSoftwareEngineer';
import JobsSales from './Pages/JobsByTitle/JobsSales';

import RemoteJobsLatam from './Pages/JobsByLocation/RemoteJobsLatam';
import RemoteJobsUsa from './Pages/JobsByLocation/RemoteJobsUsa';
import RemoteJobsUk from './Pages/JobsByLocation/RemoteJobsUk';
import RemoteJobsCanada from './Pages/JobsByLocation/RemoteJobsCanada';

import JobsInRemote from './Pages/JobsByLocation/JobsInRemote';
import JobsInArgentina from './Pages/JobsByLocation/JobsInArgentina';
import JobsInBrazil from './Pages/JobsByLocation/JobsInBrazil';
import JobsInChile from './Pages/JobsByLocation/JobsInChile';
import JobsInUsa from './Pages/JobsByLocation/JobsInUsa';
import JobsInUk from './Pages/JobsByLocation/JobsInUk';
import JobsInCanada from './Pages/JobsByLocation/JobsInCanada';
import JobsInLatam from './Pages/JobsByLocation/JobsInLatam';
import JobsInColombia from './Pages/JobsByLocation/JobsInColombia';
import JobsInMexico from './Pages/JobsByLocation/JobsInMexico';
import JobsInPeru from './Pages/JobsByLocation/JobsInPeru';
import JobsInUruguay from './Pages/JobsByLocation/JobsInUruguay';
import JobsInCostaRica from './Pages/JobsByLocation/JobsInCostaRica';
import JobsInEurope from './Pages/JobsByLocation/JobsInEurope';
import JobsInAustralia from './Pages/JobsByLocation/JobsInAustralia';
import JobsInGermany from './Pages/JobsByLocation/JobsInGermany';

import Categoryjobs from './Pages/Categoryjobs';
import AllJobsByCategory from './Pages/AllJobsByCategory';
import AllJobsByLocation from './Pages/AllJobsByLocation';
import AllJobsBySource from './Pages/AllJobsBySource';
import AllJobsByState from './Pages/AllJobsByState';
import AllJobsByCity from './Pages/AllJobsByCity';
import AllJobsByCountry from './Pages/AllJobsByCountry';

import JobsFromRemoteOk from './Pages/JobsBySource/JobsFromRemoteOk';
import JobsFromRemotive from './Pages/JobsBySource/JobsFromRemotive';
import JobsFrom4DayWeek from './Pages/JobsBySource/JobsFrom4DayWeek';
import JobsFromEchoJobs from './Pages/JobsBySource/JobsFromEchoJobs';
import JobsFromWeWorkRemotely from './Pages/JobsBySource/JobsFromWeWorkRemotely';
import JobsFromWorkable from './Pages/JobsBySource/JobsFromWorkable';
import JobsFromInfoJobs from './Pages/JobsBySource/JobsFromInfoJobs';
import JobsFromWorkingNomads from './Pages/JobsBySource/JobsFromWorkingNomads';
import JobsFromGetOnBrd from './Pages/JobsBySource/JobsFromGetOnBrd';
import JobsFromAuthenticJobs from './Pages/JobsBySource/JobsFromAuthenticJobs';
import JobsFromSpaceCrew from './Pages/JobsBySource/JobsFromSpaceCrew';
import JobsFromTryRemotely from './Pages/JobsBySource/JobsFromTryRemotely';
import JobsFromJobicy from './Pages/JobsBySource/JobsFromJobicy';
import JobsFromGreenhouse from './Pages/JobsBySource/JobsFromGreenhouse';
import JobsFromHimalayas from './Pages/JobsBySource/JobsFromHimalayas';
import JobsFromLandingJobs from './Pages/JobsBySource/JobsFromLandingJobs';
import JobsFromLaraJobs from './Pages/JobsBySource/JobsFromLaraJobs';
import JobsFromCoroflot from './Pages/JobsBySource/JobsFromCoroflot';
import JobsFromCrunchBoard from './Pages/JobsBySource/JobsFromCrunchBoard';
import JobsFromZobJobs from './Pages/JobsBySource/JobsFromZobJobs';
import JobsFromSportsTech from './Pages/JobsBySource/JobsFromSportsTech';
import JobsFromDribbble from './Pages/JobsBySource/JobsFromDribbble';
import JobsFromVueJobs from './Pages/JobsBySource/JobsFromVueJobs';
import JobsFromSvelteJobs from './Pages/JobsBySource/JobsFromSvelteJobs';
import JobsFromRemote3 from './Pages/JobsBySource/JobsFromRemote3';
import JobsFromWoodyJobs from './Pages/JobsBySource/JobsFromWoodyJobs';
import JobsFromLever from './Pages/JobsBySource/JobsFromLever';

import JobsByCompany from './Pages/JobsByCompany/JobsByCompany';

import OpenToWork from './Pages/OpenToWork';

import ApiRedirect from './Pages/ApiRedirect';
import Tools from './Pages/Tools';
import Inbox from './Pages/Inbox';

import ScrollToTop from './Element/ScrollToTop';

class Markup extends Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <div className="page-wraper">
                    <Switch>
                    <Route path='/' exact component={Browsejobgrid} />
                    <Route path='/jobs' exact component={Browsejobgrid} />
                    <Route path='/apply/*' exact component={GoToApply} />
                    <Route path='/view/*' exact component={JobDescription} />
                    <Route path='/company' exact component={JobsByCompany} />

                    <Route path='/opentowork' exact component={OpenToWork} />

                    <Route path='/api' exact component={ApiRedirect} />
                    <Route path='/tools' exact component={Tools} />
                    <Route path='/remote-jobs-in-your-inbox' exact component={Inbox} />
                    <Route path='/try-remote-jobs-in-your-inbox' exact component={UnlockJobsPage} />

                    <Route path='/remote-jobs-sales' exact component={RemoteJobsSales} />
                    <Route path='/remote-jobs-marketing' exact component={RemoteJobsMarketing} />
                    <Route path='/remote-jobs-frontend' exact component={RemoteJobsFrontend} />
                    <Route path='/remote-jobs-fullstack' exact component={RemoteJobsFullStack} />
                    <Route path='/remote-jobs-devops' exact component={RemoteJobsDevOps} />
                    <Route path='/remote-jobs-backend' exact component={RemoteJobsBackend} />
                    <Route path='/remote-jobs-customer-success' exact component={RemoteJobsCustomerSuccess} />
                    <Route path='/remote-jobs-php' exact component={RemoteJobsPhp} />
                    <Route path='/remote-jobs-python' exact component={RemoteJobsPython} />
                    <Route path='/remote-jobs-human-resource' exact component={RemoteJobsHumanResource} />
                    <Route path='/remote-jobs-data-engineer' exact component={RemoteJobsDataEngineer} />
                    <Route path='/remote-jobs-qa' exact component={RemoteJobsQa} />
                    <Route path='/remote-jobs-site-reliability-engineer' exact component={RemoteJobsSre} />
                    <Route path='/remote-jobs-accountant' exact component={RemoteJobsAccountant} />
                    <Route path='/remote-jobs-aws' exact component={RemoteJobsAws} />
                    <Route path='/remote-jobs-software-engineer' exact component={RemoteJobsSoftwareEngineer} />

                    <Route path='/jobs-laravel' exact component={JobsLaravel} />
                    <Route path='/jobs-django' exact component={JobsDjango} />
                    <Route path='/jobs-golang' exact component={JobsGolang} />
                    <Route path='/jobs-aws' exact component={JobsAws} />
                    <Route path='/jobs-javascript' exact component={JobsJavaScript} />
                    <Route path='/jobs-php' exact component={JobsPhp} />
                    <Route path='/jobs-backend' exact component={JobsBackend} />
                    <Route path='/jobs-frontend' exact component={JobsFrontend} />
                    <Route path='/jobs-fullstack' exact component={JobsFullStack} />
                    <Route path='/jobs-account-executive' exact component={JobsAccountExecutive} />
                    <Route path='/jobs-customer-success' exact component={JobsCustomerSuccess} />
                    <Route path='/jobs-python' exact component={JobsPython} />
                    <Route path='/jobs-marketing' exact component={JobsMarketing} />
                    <Route path='/jobs-ruby' exact component={JobsRuby} />
                    <Route path='/jobs-react' exact component={JobsReact} />
                    <Route path='/jobs-software-engineer' exact component={JobsSoftwareEngineer} />
                    <Route path='/jobs-sales' exact component={JobsSales} />

                    <Route path='/remote-sales-jobs' exact><Redirect to='/remote-jobs-sales' /></Route>
                    <Route path='/remote-marketing-jobs' exact><Redirect to='/remote-jobs-marketing' /></Route>
                    <Route path='/remote-backend-jobs' exact><Redirect to='/remote-jobs-backend' /></Route>
                    <Route path='/remote-frontend-jobs' exact><Redirect to='/remote-jobs-frontend' /></Route>
                    <Route path='/remote-fullstack-jobs' exact><Redirect to='/remote-jobs-fullstack' /></Route>
                    <Route path='/remote-devops-jobs' exact><Redirect to='/remote-jobs-devops' /></Route>
                    <Route path='/remote-customer-success-jobs' exact><Redirect to='/remote-jobs-customer-success' /></Route>
                    <Route path='/remote-php-jobs' exact><Redirect to='/remote-jobs-php' /></Route>
                    <Route path='/remote-python-jobs' exact><Redirect to='/remote-jobs-python' /></Route>
                    <Route path='/remote-human-resource-jobs' exact><Redirect to='/remote-jobs-human-resource' /></Route>
                    <Route path='/remote-data-engineer-jobs' exact><Redirect to='/remote-jobs-data-engineer' /></Route>

                    <Route path='/remote-jobs-latam' exact component={RemoteJobsLatam} />
                    <Route path='/remote-jobs-usa' exact component={RemoteJobsUsa} />
                    <Route path='/remote-jobs-canada' exact component={RemoteJobsCanada} />
                    <Route path='/remote-jobs-uk' exact component={RemoteJobsUk} />
                    <Route path='/remote-jobs-worldwide' exact component={JobsInRemote} />
                    <Route path='/jobs-in-argentina' exact component={JobsInArgentina} />
                    <Route path='/jobs-in-brazil' exact component={JobsInBrazil} />
                    <Route path='/jobs-in-chile' exact component={JobsInChile} />
                    <Route path='/jobs-in-usa' exact component={JobsInUsa} />
                    <Route path='/jobs-in-united-kingdom' exact component={JobsInUk} />
                    <Route path='/jobs-in-canada' exact component={JobsInCanada} />
                    <Route path='/jobs-in-latam' exact component={JobsInLatam} />
                    <Route path='/jobs-in-colombia' exact component={JobsInColombia} />
                    <Route path='/jobs-in-mexico' exact component={JobsInMexico} />
                    <Route path='/jobs-in-peru' exact component={JobsInPeru} />
                    <Route path='/jobs-in-uruguay' exact component={JobsInUruguay} />
                    <Route path='/jobs-in-costa-rica' exact component={JobsInCostaRica} />
                    <Route path='/jobs-in-europe' exact component={JobsInEurope} />
                    <Route path='/jobs-in-australia' exact component={JobsInAustralia} />
                    <Route path='/jobs-in-germany' exact component={JobsInGermany} />

                    <Route path='/jobs-by-category' exact component={Categoryjobs} />
                    <Route path='/all-jobs-by-category' exact component={AllJobsByCategory} />
                    <Route path='/all-jobs-by-location' exact component={AllJobsByLocation} />
                    <Route path='/all-jobs-by-source' exact component={AllJobsBySource} />
                    <Route path='/all-jobs-by-state' exact component={AllJobsByState} />
                    <Route path='/all-jobs-by-city' exact component={AllJobsByCity} />
                    <Route path='/all-jobs-by-country' exact component={AllJobsByCountry} />

                    <Route path='/jobs-from-remoteok' exact component={JobsFromRemoteOk} />
                    <Route path='/jobs-from-remotive' exact component={JobsFromRemotive} />
                    <Route path='/jobs-from-4dayweek' exact component={JobsFrom4DayWeek} />
                    <Route path='/jobs-from-echojobs' exact component={JobsFromEchoJobs} />
                    <Route path='/jobs-from-weworkremotely' exact component={JobsFromWeWorkRemotely} />
                    <Route path='/jobs-from-workable' exact component={JobsFromWorkable} />
                    <Route path='/jobs-from-infojobs' exact component={JobsFromInfoJobs} />
                    <Route path='/jobs-from-workingnomads' exact component={JobsFromWorkingNomads} />
                    <Route path='/jobs-from-getonbrd' exact component={JobsFromGetOnBrd} />
                    <Route path='/jobs-from-authenticjobs' exact component={JobsFromAuthenticJobs} />
                    <Route path='/jobs-from-spacecrew' exact component={JobsFromSpaceCrew} />
                    <Route path='/jobs-from-tryremotely' exact component={JobsFromTryRemotely} />
                    <Route path='/jobs-from-jobicy' exact component={JobsFromJobicy} />
                    <Route path='/jobs-from-greenhouse' exact component={JobsFromGreenhouse} />
                    <Route path='/jobs-from-himalayas' exact component={JobsFromHimalayas} />
                    <Route path='/jobs-from-landingjobs' exact component={JobsFromLandingJobs} />
                    <Route path='/jobs-from-larajobs' exact component={JobsFromLaraJobs} />
                    <Route path='/jobs-from-coroflot' exact component={JobsFromCoroflot} />
                    <Route path='/jobs-from-crunchboard' exact component={JobsFromCrunchBoard} />
                    <Route path='/jobs-from-zobjobs' exact component={JobsFromZobJobs} />
                    <Route path='/jobs-from-sportstech' exact component={JobsFromSportsTech} />
                    <Route path='/jobs-from-dribbble' exact component={JobsFromDribbble} />
                    <Route path='/jobs-from-vuejobs' exact component={JobsFromVueJobs} />
                    <Route path='/jobs-from-sveltejobs' exact component={JobsFromSvelteJobs} />
                    <Route path='/jobs-from-remote3' exact component={JobsFromRemote3} />
                    <Route path='/jobs-from-woodyjobs' exact component={JobsFromWoodyJobs} />
                    <Route path='/jobs-from-lever' exact component={JobsFromLever} />

                    <Route path='/post' exact component={PostJob} />
                    <Route path='/thankyou' exact component={ThankYou} />
                    <Route path='/sponsors' exact component={Sponsors} />
                    <Route path='/contact' exact component={Contact} />
                    <Route path='/blog' exact component={Blog} />
                    <Route path='/blog/remote-work-productivity-tips' exact component={BlogFirstPost} />
                    <Route path='/blog/building-tools-to-support-remote-work' exact component={BlogPostBuildingTools} />
                    <Route path='/blog/embracing-remote-work' exact component={BlogPostEmbracing} />
                    <Route path='*' exact component={Error404} />
                    </Switch>
                </div>
                <ScrollToTop />
            </BrowserRouter>
        );
    }
};

export default Markup;
