import React, {Component} from 'react';
import {Link} from 'react-router-dom';

var img10 = require('./../../images/city/bsas.jpeg');
var img20 = require('./../../images/city/pic4.jpg');
var img30 = require('./../../images/city/uk.jpeg');
var img40 = require('./../../images/city/canada.jpeg');

var img50 = require('./../../images/city/brazil.png');
var img60 = require('./../../images/city/world.jpeg');
var img70 = require('./../../images/city/sidney.jpeg');
var img80 = require('./../../images/city/munich.jpeg');

class Featureblog extends Component {
    render() {
        return (
            <div className="section-full content-inner bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 section-head text-center">
                            <h2 className="m-b5">Explore Jobs By Location</h2>
                            <h6 className="fw4 m-b0">You can search for jobs by country and city</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/remote-jobs-worldwide'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img60 + ")"}}>
                                <div className="city-info">
                                    <h5>Worldwide <span role="img" aria-label="World Flag">🌎</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-usa'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img20 + ")"}}>
                                <div className="city-info">
                                    <h5>USA <span role="img" aria-label="United States Flag">🇺🇸</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-canada'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img40 + ")"}}>
                                <div className="city-info">
                                    <h5>Canada <span role="img" aria-label="Canada Flag">🇨🇦</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-australia'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img70 + ")"}}>
                                <div className="city-info">
                                    <h5>Australia <span role="img" aria-label="Australia Flag">🇦🇺</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-argentina'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img10 + ")"}}>
                                <div className="city-info">
                                    <h5>Argentina <span role="img" aria-label="Argentina Flag">🇦🇷</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-brazil'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img50 + ")"}}>
                                <div className="city-info">
                                    <h5>Brazil <span role="img" aria-label="Brazil Flag">🇧🇷</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-germany'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img80 + ")"}}>
                                <div className="city-info">
                                    <h5>Germany <span role="img" aria-label="Germany Flag">🇩🇪</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-united-kingdom'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img30 + ")"}}>
                                <div className="city-info">
                                    <h5>UK <span role="img" aria-label="United Kingdom Flag">🇬🇧</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center m-t30" style={{display: ""}}>
                            <Link to={"/all-jobs-by-location"} className="dez-tilte"><button className="site-button radius-l button-lg">View All Locations</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Featureblog;
