import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';

const randomNumber = Math.floor(Math.random() * 5) + 10;
const bnr = require('./../../images/banner/bnr' + randomNumber + '.jpg');

class AllJobsByState extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Explore Job Listings by State Across the USA</title>
                    <meta name="description" content="Explore a variety of job opportunities categorized by state Across the USA. Find your next remote work opportunity. #OpenToWorkRemote" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/all-jobs-by-state" />
                </Helmet>

                <div className="page-content">
                    <div className="dez-bnr-inr jobs-category overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <Link to = {"/"}>
                                    <h1 className="text-white">
                                        Browse Job Postings by State Across the USA
                                    </h1>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="section-full content-inner jobs-category-bx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Select a State</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=Alabama">Alabama</Link></li>
                                                        <li><Link to="/jobs?title=&location=Alaska">Alaska</Link></li>
                                                        <li><Link to="/jobs?title=&location=Arizona">Arizona</Link></li>
                                                        <li><Link to="/jobs?title=&location=Arkansas">Arkansas</Link></li>
                                                        <li><Link to="/jobs?title=&location=California">California</Link></li>
                                                        <li><Link to="/jobs?title=&location=Colorado">Colorado</Link></li>
                                                        <li><Link to="/jobs?title=&location=Connecticut">Connecticut</Link></li>
                                                        <li><Link to="/jobs?title=&location=Delaware">Delaware</Link></li>
                                                        <li><Link to="/jobs?title=&location=Florida">Florida</Link></li>
                                                        <li><Link to="/jobs?title=&location=Georgia">Georgia</Link></li>
                                                        <li><Link to="/jobs?title=&location=Hawaii">Hawaii</Link></li>
                                                        <li><Link to="/jobs?title=&location=Idaho">Idaho</Link></li>
                                                        <li><Link to="/jobs?title=&location=Illinois">Illinois</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=Indiana">Indiana</Link></li>
                                                        <li><Link to="/jobs?title=&location=Iowa">Iowa</Link></li>
                                                        <li><Link to="/jobs?title=&location=Kansas">Kansas</Link></li>
                                                        <li><Link to="/jobs?title=&location=Kentucky">Kentucky</Link></li>
                                                        <li><Link to="/jobs?title=&location=Louisiana">Louisiana</Link></li>
                                                        <li><Link to="/jobs?title=&location=Maine">Maine</Link></li>
                                                        <li><Link to="/jobs?title=&location=Maryland">Maryland</Link></li>
                                                        <li><Link to="/jobs?title=&location=Massachusetts">Massachusetts</Link></li>
                                                        <li><Link to="/jobs?title=&location=Michigan">Michigan</Link></li>
                                                        <li><Link to="/jobs?title=&location=Minnesota">Minnesota</Link></li>
                                                        <li><Link to="/jobs?title=&location=Mississippi">Mississippi</Link></li>
                                                        <li><Link to="/jobs?title=&location=Missouri">Missouri</Link></li>
                                                        <li><Link to="/jobs?title=&location=Montana">Montana</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=Nebraska">Nebraska</Link></li>
                                                        <li><Link to="/jobs?title=&location=Nevada">Nevada</Link></li>
                                                        <li><Link to="/jobs?title=&location=New Hampshire">New Hampshire</Link></li>
                                                        <li><Link to="/jobs?title=&location=New Jersey">New Jersey</Link></li>
                                                        <li><Link to="/jobs?title=&location=New Mexico">New Mexico</Link></li>
                                                        <li><Link to="/jobs?title=&location=New York">New York</Link></li>
                                                        <li><Link to="/jobs?title=&location=North Carolina">North Carolina</Link></li>
                                                        <li><Link to="/jobs?title=&location=North Dakota">North Dakota</Link></li>
                                                        <li><Link to="/jobs?title=&location=Ohio">Ohio</Link></li>
                                                        <li><Link to="/jobs?title=&location=Oklahoma">Oklahoma</Link></li>
                                                        <li><Link to="/jobs?title=&location=Oregon">Oregon</Link></li>
                                                        <li><Link to="/jobs?title=&location=Pennsylvania">Pennsylvania</Link></li>
                                                        <li><Link to="/jobs?title=&location=Rhode Island">Rhode Island</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=South Carolina">South Carolina</Link></li>
                                                        <li><Link to="/jobs?title=&location=South Dakota">South Dakota</Link></li>
                                                        <li><Link to="/jobs?title=&location=Tennessee">Tennessee</Link></li>
                                                        <li><Link to="/jobs?title=&location=Texas">Texas</Link></li>
                                                        <li><Link to="/jobs?title=&location=Utah">Utah</Link></li>
                                                        <li><Link to="/jobs?title=&location=Vermont">Vermont</Link></li>
                                                        <li><Link to="/jobs?title=&location=Virginia">Virginia</Link></li>
                                                        <li><Link to="/jobs?title=&location=Washington">Washington</Link></li>
                                                        <li><Link to="/jobs?title=&location=West Virginia">West Virginia</Link></li>
                                                        <li><Link to="/jobs?title=&location=Wisconsin">Wisconsin</Link></li>
                                                        <li><Link to="/jobs?title=&location=Wyoming">Wyoming</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default AllJobsByState;
