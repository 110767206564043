import React, {Component} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';
import UnlockJobs from './../Element/UnlockJobs';

class UnlockJobsPage extends Component {
    render() {
        return (
            <div>
                <Header />

                <Helmet>
                    <title>Unlock All Remote Jobs and Receive Them in Your Inbox</title>
                    <meta name="description" content="Unlock all remote jobs. +8642 more job opportunities available. Subscribe once and discover all jobs." />
                    <link rel="canonical" href="https://www.opentoworkremote.com/try-remote-jobs-in-your-inbox" />
                </Helmet>

                <UnlockJobs />

                <Footer />

            </div>
        );
    }
};

export default UnlockJobsPage;
