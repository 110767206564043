import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Footer from "./../Layout/Footer2";
import Header from "./../Layout/Header";
import { Helmet } from 'react-helmet';
import LogoComponent from './../Element/LogoComponent';

const axios = require("axios");

class JobDescription extends Component {

    constructor(props) {
        super(props);

        const jobId = window.location.pathname.split('/')[2];

        this.getJob(jobId);

        this.state = {
            myresponse: []
        };
    }

    getJob(jobId) {
        var options = {};
        var headers = {};
        var url = "";

        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            url = 'http://localhost:8080/jobs/' + jobId;
        } else {
            url = "https://open-to-work-remote-api.p.rapidapi.com/jobs/" + jobId;
            headers = {
                "X-RapidAPI-Key": "61c95e0e86msh784d6d5dfb4761dp1ed5e9jsn7923c8051dc2",
                "X-RapidAPI-Host": "open-to-work-remote-api.p.rapidapi.com",
                "X-Source": "OpenToWorkRemote.com",
            };
        }

        options = {
            method: "GET",
            url: url,
            params: {},
            headers: headers
        };

        axios
            .request(options)
            .then((response) => {
                this.setState({
                    myresponse: response.data
                });
            })
            .catch(function (error) {
                console.error(error);
                if (error.response && error.response.status === 404) {
                    console.log('Job Not Found, redirecting...');
                    window.location.href = '/';
                }
            });
    }

    decodeEntities(html) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = html;
      return textarea.value;
    }

    render() {

        const { myresponse } = this.state;

        var canonical = "https://www.opentoworkremote.com/view/" + myresponse.id;

        const showGoBackButton = window.history.length > 1;

        const publicationDate = new Date(myresponse.publicationDate2);
        const currentDate = new Date();
        const differenceInTime = currentDate - publicationDate;
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        const isMoreThan30Days = differenceInDays > 31 + 1;

        const showTheJobDescription = myresponse.description && ['OpenToWorkRemote', 'Greenhouse', 'Lever', 'WWR', 'Workable', 'Remotive', 'WorkingNomads', 'Himalayas', 'LandingJobs', 'GetOnBrd', 'Zobjobs', 'SportsTech'].includes(myresponse.source);

        let urlToApply = `/apply/${myresponse.id}?url=${myresponse.url}`;
        if (myresponse && myresponse.url && myresponse.url.toString().indexOf("@") !== -1) {
            urlToApply = "mailto:" + myresponse.url;
        }
//        console.log('urlToApply:', urlToApply);

        return (
            <div>
                <Helmet>
                    <title>Open To Work Remote: Job Detail</title>
                    <meta name="description" content="Explore our comprehensive job descriptions to find the perfect career opportunity for you. Learn about job responsibilities, qualifications, and benefits." />
                    <link rel="canonical" href={canonical} />
                </Helmet>

                <div className="page-wraper">
                    <Header />

                    <div className="page-content bg-white">

                        <div className="dez-bnr-inr bg-dark" style={{height: "100px"}}>
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <h1 className="text-white" style={{margin: "25px"}}>View Job Information</h1>
                                </div>
                            </div>
                        </div>

                        {isMoreThan30Days &&
                        <div className="content-block">
                            <div className="section-full content-inner-1">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-4"></div>
                                        <div className="col-lg-5">
                                            <div className="sticky-top">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-6">
                                                        <div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
                                                            <h4 className="text-red text-center">ATTENTION:</h4>
                                                            <h6 className="text-black text-center">This job post may be old since it was posted more than 30 days ago.</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        <div className="content-block">

                            <div className="section-full content-inner-1">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-4"></div>
                                        <div className="col-lg-5">
                                            <div className="sticky-top">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-6">
                                                        <div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
                                                            <br />
                                                            <div className="text-center">
                                                                <LogoComponent logoUrl={myresponse.logo} />
                                                            </div>
                                                            <h2 className="text-black font-weight-700 p-t15 m-b15 text-center">{myresponse.company} is Hiring</h2>
                                                            <h5 className="text-black font-weight-700 p-t10 m-b15 text-left">Job Info:</h5>
                                                            <ul>
                                                                <li><i className="ti-target"></i><strong className="font-weight-700 text-black">Company</strong><span className="text-black-light"><Link to={`/company?q=${myresponse.company}`} > {myresponse.company} </Link></span></li>
                                                                <li><i className="ti-marker-alt"></i><strong className="font-weight-700 text-black">Position</strong><span className="text-black-light"> <Link to={`/jobs?title=${myresponse.title}`} > {myresponse.title} </Link> </span></li>
                                                                {myresponse.location && (
                                                                <li><i className="ti-location-pin"></i><strong className="font-weight-700 text-black">Location</strong><span className="text-black-light"> <Link to={`/jobs?location=${myresponse.location}`} > {myresponse.location} </Link> </span></li>
                                                                )}
                                                                <li><i className="ti-search"></i><strong className="font-weight-700 text-black">Source</strong><span className="text-black-light"> <Link to={`/jobs?source=${myresponse.source}`} > {myresponse.source} </Link></span> </li>
                                                                <li><i className="ti-calendar"></i><strong className="font-weight-700 text-black">Published</strong><span className="text-black-light"> {myresponse.publicationDate2} </span> {isMoreThan30Days && <span className="text-red old-publication"><br />More than 30 days ago.</span>}</li>
                                                                {myresponse.workType && (
                                                                <li><i className="ti-time"></i><strong className="font-weight-700 text-black">Type</strong> {myresponse.workType} </li>
                                                                )}
                                                                {myresponse.experience && (
                                                                <li><i className="ti-user"></i><strong className="font-weight-700 text-black">Experience</strong> {myresponse.experience} </li>
                                                                )}
                                                                {myresponse.salaryRange && (
                                                                <li><i className="ti-money"></i><strong className="font-weight-700 text-black">Salary</strong> {myresponse.salaryRange} </li>
                                                                )}
                                                            </ul>
                                                            <br />
                                                            {!showTheJobDescription && !isMoreThan30Days && (
                                                                <div className="text-center">
                                                                    <a href={urlToApply}
                                                                        className="site-button apply-button"
                                                                        target="_blank"
                                                                        rel="nofollow noopener noreferrer"
                                                                        >
                                                                        Go To Job Description
                                                                    </a>
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    {showGoBackButton && (
                                                                        <Link className="apply-button" to="#" onClick={() => window.history.back()}>
                                                                          <span className="ti-back-left"> Go Back</span>
                                                                        </Link>
                                                                    )}
                                                                    <br /><br />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {showTheJobDescription && !isMoreThan30Days && (
                                                                <div>
                                                                    <br />
                                                                    <h4 className="text-black font-weight-700 p-t10 m-b15 text-center">Job Description:</h4>
                                                                    <div className="text-black p-l10 m-l10 p-r5 m-r5 job-description" dangerouslySetInnerHTML={{ __html: this.decodeEntities(myresponse.description) }} />
                                                                    <br />
                                                                    <div className="text-center">
                                                                        <a href={urlToApply}
                                                                            className="site-button apply-button"
                                                                            target="_blank"
                                                                            rel="nofollow noopener noreferrer"
                                                                            >
                                                                            Go To Apply
                                                                        </a>
                                                                        &nbsp;&nbsp;&nbsp;
                                                                        {showGoBackButton && (
                                                                            <Link className="apply-button" to="#" onClick={() => window.history.back()}>
                                                                              <span className="ti-back-left"> Go Back</span>
                                                                            </Link>
                                                                        )}
                                                                        <br /><br />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
};

export default JobDescription;
