import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../../Layout/Header';
import Footer from './../../Layout/Footer2';
import { Helmet } from 'react-helmet';

//var bnr = require('./../../../images/banner/bnr1.jpg');

const randomNumber = Math.floor(Math.random() * 5) + 10;
const bnr = require('./../../../images/banner/bnr' + randomNumber + '.jpg');

class BlogPostBuildingTools extends Component {
    render() {
        return (
            <div>
                <Header />

                <Helmet>
                    <title>I'm building a series of tools to support remote work</title>
                    <meta name="description" content="Developing Mini Tools for Remote Work and Job Discovery: I am building a series of mini tools designed to support remote work and help people find jobs." />
                    <link rel="canonical" href="https://www.opentoworkremote.com/blog/building-tools-to-support-remote-work" />
                </Helmet>

                <div className="page-content bg-white">

                    <div className="dez-bnr-inr overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <h1 className="text-white">Open To Work Remote: Blog</h1>
                            </div>
                        </div>
                    </div>

                    <div className="content-area">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-3 col-md-3 m-3">
                                    <div className="blog-post blog-single blog-style-1">
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 m-6">

                                    <div className="blog-post blog-single blog-style-1">
                                        <div className="dez-post-meta">
                                            <ul className="d-flex align-items-center">
                                                <li className="post-date"><i className="fa fa-calendar"></i>April 12, 2024</li>
                                                <li className="post-author"><i className="fa fa-user"></i>By <b><Link to={"/contact"} className="">Mauro</Link></b></li>
                                            </ul>
                                        </div>
                                        <div className="dez-post-title">
                                            <br /><h2 className="post-title m-t0">I'm building a series of tools to support remote work</h2>
                                        </div>
                                        <div className="dez-post-media dez-img-effect zoom-slow m-t20"> 
                                            <Link to={"#"}><img src={require("./../../../images/blog/default/cover.png") }alt="" /></Link>
                                        </div>
                                        <div className="dez-post-text">

                                            <h4 className="text-center">Hey, There! <span role="img" aria-label="happy person saying hi">😃 👋</span>️</h4>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; I wanted to share some exciting news with you all. I am building a series of <b>mini tools</b> designed to support remote work and help people find jobs.</p>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; With the current global situation, many of us have transitioned from working remotely to start looking for a job, which has its own unique set of challenges. Throughout this experience, I’ve realized there’s a need to solve problems requiring practical solutions. These mini tools aim to spread the word about remote job opportunities worldwide and make it a little easier to find jobs, regardless of their physical locations.</p>

                                            <h5>Some of the tools that I’m working on:</h5>
                                            <hr />

                                            <h4>1- Job Aggregator:️ <span role="img" aria-label="person working and briefcase">👨‍💻️ 💼</span></h4>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; Introducing the first tool in my series: a website designed to assist IT talents living in LATAM in finding exceptional remote job opportunities with better salaries.</p>

                                            <p>You can expect from my Job Board:</p>

                                            <p>
                                                - Extensive job listings.
                                                <br />
                                                - Search and filters.
                                                <br />
                                                - Salaries info. <em>(It’s not easy to find info about salaries. I’ll be working more on this.)</em>
                                            </p>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; I am incredibly excited about this initiative. I aim to facilitate your job search and help you to secure rewarding remote opportunities that match your skills and aspirations.</p>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; Stay tuned for updates on the launch of my Job Board. In the beginning, for IT professionals in LATAM, but then for all kinds of job categories and opportunities around the world.</p>

                                            <h5>URL: <Link to = {"/"} >OpenToWorkRemote.com</Link></h5>

                                            <p><img src="https://dev-to-uploads.s3.amazonaws.com/uploads/articles/ym0chfm3d8muiuzlcr4x.png" alt="Job Board Screenshot" /></p>

                                            <div className="text-center">
                                                <Link to = {"/"} className="site-button m-t20 outline outline-2 radius-l button-md text-center">Go to the Job Board</Link>
                                            </div>

                                            <br /><br /><hr /><br />

                                            <h4>2- Job API: <span role="img" aria-label="monitor worldwide">🖥️ 🌐</span></h4>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; The second tool in my series: is a Job API. This powerful API provides access to a vast database of job opportunities, making the job search process even more efficient and tailored to your preferences. The data is paginated and allows you to filter jobs by title, location, technology, company, and salary.</p>

                                            <p>About this API:</p>

                                            <p>
                                                - Paginated results.
                                                <br />
                                                - Jobs updates daily.
                                                <br />
                                                - Remote job listings.
                                                <br />
                                                - Advanced filtering options.
                                            </p>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; The Job API could be a game-changer for developers, job seekers, recruiters, and companies. The API allows access to a wealth of hidden job listings in a targeted and efficient manner. More releases are coming soon for this API and his documentation details.</p>

                                            <h5>Website: <a href="https://api.opentoworkremote.com" target="_blank" rel="noopener noreferrer">API</a>.</h5>

                                            <h5>Documentation: <a href="https://rapidapi.com/maurobonfietti/api/open-to-work-remote-api/" target="_blank" rel="noopener noreferrer">RapidAPI</a>.</h5>

                                            <p><img src="https://dev-to-uploads.s3.amazonaws.com/uploads/articles/giniuf17gfe3ld5xf887.png" alt="Job API Landing Page" /></p>

                                            <div className="text-center">
                                                <a href="https://api.opentoworkremote.com" target="_blank" className="site-button m-t20 outline outline-2 radius-l button-md" rel="noopener noreferrer">Go to the API Website</a>
                                                &nbsp; &nbsp;
                                                <a href="https://rapidapi.com/maurobonfietti/api/open-to-work-remote-api/" target="_blank" className="site-button m-t20 outline outline-2 radius-l button-md" rel="noopener noreferrer">Go to the API Docs</a>
                                            </div>

                                            <br /><br /><hr /><br />

                                            <h4>3- Telegram group: <span role="img" aria-label="community">🤖 👥</span></h4>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; The third one: I share job searches in a Telegram group. This leverages the power of Telegram with a bot that brings you a selection of job opportunities on a daily basis.</p>

                                            <p>About the Telegram group:</p>

                                            <p>
                                                - Multiple job posts.
                                                <br />
                                                - Community growing.
                                                <br />
                                                - Easy application process <em>(feature pending to be developed).</em>
                                                <br />
                                                - Topics and job categories.
                                                <br />
                                                - Daily updates.
                                            </p>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; Join the group today to receive daily remote job opportunities directly in your Telegram account. It’s a convenient way to stay informed, explore new career paths, and take it to the next level! I can’t wait to have you on board and help you discover exciting remote job prospects tailored to your interests and skills.</p>

                                            <h5>Join us on: <a href="https://t.me/opentoworkremote" target="_blank" rel="noopener noreferrer">Telegram</a>.</h5>

                                            <p><img src="https://dev-to-uploads.s3.amazonaws.com/uploads/articles/btpkn3j056vnw8w13orq.png" alt="Telegram Group" /></p>

                                            <div className="text-center">
                                                <a href="https://t.me/opentoworkremote" target="_blank" className="site-button m-t20 outline outline-2 radius-l button-md" rel="noopener noreferrer">Join us on Telegram</a>
                                            </div>

                                            <br /><br /><hr /><br />

                                            <h4>4- Remote Jobs List: <span role="img" aria-label="remote jobs list">🌎 📃</span></h4>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; The remote jobs list is my series’s fourth and last mini-tool. This comprehensive collection of remote job opportunities worldwide is organized in a README file in Markdown format, trying to make it easy for job seekers to explore all positions in one place.</p>

                                            <p>Remote Jobs List:</p>

                                            <p>
                                                - Onsite positions.
                                                <br />
                                                - 100% remote positions.
                                                <br />
                                                - Varied industries positions.
                                                <br />
                                                - Readme in Markdown format.
                                                <br />
                                                - Public repository on GitHub.
                                            </p>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; Whether you’re seeking a remote-first company or a fully remote position, this list covers you.</p>

                                            <h5>Check it out: <a href="https://github.com/maurobonfietti/remote-jobs" target="_blank" rel="noopener noreferrer">GitHub Repo</a>.</h5>

                                            <p><img src="https://dev-to-uploads.s3.amazonaws.com/uploads/articles/ggcioo4r982ey7r8fisv.png" alt="Remote Jobs List Example" /></p>

                                            <div className="text-center">
                                                <a href="https://github.com/maurobonfietti/remote-jobs" target="_blank" className="site-button m-t20 outline outline-2 radius-l button-md" rel="noopener noreferrer">Check it out on GitHub</a>
                                            </div>

                                            <br /><br /><hr /><br />

                                            <h4>5- Twitter:</h4>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; Our bot brings you the latest remote job listings directly to your Twitter feed. Follow us now and don't miss out on any opportunities!</p>

                                            <div className="text-center">
                                                <h5>Follow us <a href="https://twitter.com/open2workremote" target="_blank" rel="noopener noreferrer">on Twitter</a>.</h5>
                                                <a href="https://twitter.com/open2workremote" target="_blank" className="site-button m-t20 outline outline-2 radius-l button-md" rel="noopener noreferrer">Follow us on X</a>
                                            </div>

                                            <br /><br /><hr /><br />

                                            <h4>6- Discord Server:</h4>

                                            <p>&nbsp;&nbsp;&nbsp;&nbsp; Explore remote jobs from top companies and connect with like-minded professionals. Join our Discord server today and take the next step in your remote career journey!</p>

                                            <div className="text-center">
                                                <h5>Join Us: <a href="https://discord.gg/jpgHuV7e3Y" target="_blank" rel="noopener noreferrer">Discord Invite</a>.</h5>
                                                <a href="https://discord.gg/jpgHuV7e3Y" target="_blank" className="site-button m-t20 outline outline-2 radius-l button-md" rel="noopener noreferrer">Remote Jobs on Discord</a>
                                            </div>

                                            <br /><br /><hr /><br />

                                            <h5>More tools are coming soon...<span role="img" aria-label="Love">😃</span></h5>
                                            <p>
                                                Well, that's all for today. Thank you for reading!
                                            </p>
                                            <p>
                                                <span role="img" aria-label="Love">👋️</span> <b> Mauro.</b> 
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>	
                <Footer />
            </div>
        );
    }
};

export default BlogPostBuildingTools;
