import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import RenderJobs from './../RenderJobs';
import api from './../Api';

const axios = require('axios');

class JobsFromRemoteOk extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: ''
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', '', 'remoteok'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage
                });
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs from RemoteOK: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs from RemoteOK? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-from-remoteok" />
                </Helmet>

                <RenderJobs
                    title='Find jobs from RemoteOK'
                    jobs={jobs}
                    total={total}
                    page={page}
                    pages={pages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                />

                <Footer />
            </div>
        );
    }
}

export default JobsFromRemoteOk;
