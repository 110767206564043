import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';

const randomNumber = Math.floor(Math.random() * 5) + 10;
const bnr = require('./../../images/banner/bnr' + randomNumber + '.jpg');

class AllJobsByCategory extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Discover Jobs Across Categories</title>
                    <meta name="description" content="Explore a diverse range of jobs across various categories. Your gateway to new opportunities. #OpenToWorkRemote" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/all-jobs-by-category" />
                </Helmet>

                <div className="page-content">
                    <div className="dez-bnr-inr jobs-category overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <Link to = {"/"}>
                                <h1 className="text-white">
                                    Browse Job Listings by Category
                                </h1>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="section-full content-inner jobs-category-bx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Jobs on Human Resources</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=HR%20"}>HR</Link></li>
                                                        <li><Link to = {"/jobs?title=Recruiter"}>Recruiter</Link></li>
                                                        <li><Link to = {"/jobs?title=Talent Acquisition"}>Talent Acquisition</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Web Development Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs-backend"}>Backend</Link></li>
                                                        <li><Link to = {"/jobs-frontend"}>Frontend</Link></li>
                                                        <li><Link to = {"/jobs-fullstack"}>Full Stack</Link></li>
                                                        <li><Link to = {"/jobs?title=Web Developer"}>Web Developer</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Writing Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Copywriter"}>Copywriter</Link></li>
                                                        <li><Link to = {"/jobs?title=Content Writer"}>Content Writer</Link></li>
                                                        <li><Link to = {"/jobs?title=Technical Writer"}>Technical Writer</Link></li>
                                                        <li><Link to = {"/jobs?title=Community Manager"}>Community Manager</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Data Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Data Entry"}>Data Entry</Link></li>
                                                        <li><Link to = {"/jobs?title=Data Analyst"}>Data Analyst</Link></li>
                                                        <li><Link to = {"/jobs?title=Data Scientist"}>Data Scientist</Link></li>
                                                        <li><Link to = {"/jobs?title=Data Engineer"}>Data Engineer</Link></li>
                                                        <li><Link to = {"/jobs?title=Data Architect"}>Data Architect</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Jobs on Finance</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Finance"}>Finance</Link></li>
                                                        <li><Link to = {"/jobs?title=Payroll"}>Payroll</Link></li>
                                                        <li><Link to = {"/jobs?title=Accountant"}>Accountant</Link></li>
                                                        <li><Link to = {"/jobs?title=Risk Analyst"}>Risk Analyst</Link></li>
                                                        <li><Link to = {"/jobs?title=Compensation"}>Compensation</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Jobs on Product</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Product Owner"}>Product Owner</Link></li>
                                                        <li><Link to = {"/jobs?title=Product Manager"}>Product Manager</Link></li>
                                                        <li><Link to = {"/jobs?title=Product Analyst"}>Product Analyst</Link></li>
                                                        <li><Link to = {"/jobs?title=Product Designer"}>Product Designer</Link></li>
                                                        <li><Link to = {"/jobs?title=Product Engineer"}>Product Engineer</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Jobs on Project</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Project Manager"}>Project Manager</Link></li>
                                                        <li><Link to = {"/jobs?title=Release Engineer"}>Release Engineer</Link></li>
                                                        <li><Link to = {"/jobs?title=Release Manager"}>Release Manager</Link></li>
                                                        <li><Link to = {"/jobs?title=Program Manager"}>Program Manager</Link></li>
                                                        <li><Link to = {"/jobs?title=Scrum Master"}>Scrum Master</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Design Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Designer"}>Designer</Link></li>
                                                        <li><Link to = {"/jobs?title=UI/UX"}>UI/UX</Link></li>
                                                        <li><Link to = {"/jobs?title=Artist"}>Artist</Link></li>
                                                        <li><Link to = {"/jobs?title=Animator"}>Animator</Link></li>
                                                        <li><Link to = {"/jobs?title=Video Editor"}>Video Editor</Link></li>
                                                        <li><Link to = {"/jobs?title=Graphic Designer"}>Graphic Designer</Link></li>
                                                        <li><Link to = {"/jobs?title=Product Designer"}>Product Designer</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Customer Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs-customer-success"}>Customer Success</Link></li>
                                                        <li><Link to = {"/jobs?title=Customer Support"}>Customer Support</Link></li>
                                                        <li><Link to = {"/jobs?title=Customer Service"}>Customer Service</Link></li>
                                                        <li><Link to = {"/jobs?title=Account Manager"}>Account Manager</Link></li>
                                                        <li><Link to = {"/jobs?title=Customer Success Manager"}>Customer Success Manager</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Support Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Technical Support"}>Technical Support</Link></li>
                                                        <li><Link to = {"/jobs?title=Support Engineer"}>Support Engineer</Link></li>
                                                        <li><Link to = {"/jobs?title=Product Support"}>Product Support</Link></li>
                                                        <li><Link to = {"/jobs?title=Call Center"}>Call Center</Link></li>
                                                        <li><Link to = {"/jobs?title=Help Desk"}>Help Desk</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Business Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Business Analyst"}>Business Analyst</Link></li>
                                                        <li><Link to = {"/jobs?title=Business Operations"}>Business Operations</Link></li>
                                                        <li><Link to = {"/jobs?title=Business Intelligence"}>Business Intelligence</Link></li>
                                                        <li><Link to = {"/jobs?title=Business Development"}>Business Development</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Sales & Marketing Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs-sales"}>Sales</Link></li>
                                                        <li><Link to = {"/jobs-marketing"}>Marketing</Link></li>
                                                        <li><Link to = {"/jobs?title=Paid Media"}>Paid Media</Link></li>
                                                        <li><Link to = {"/jobs?title=SEO"}>SEO</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Infrastructure Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=DevOps"}>DevOps</Link></li>
                                                        <li><Link to = {"/jobs?title=Network Engineer"}>Network Engineer</Link></li>
                                                        <li><Link to = {"/jobs?title=Site Reliability Engineer"}>Site Reliability Engineer</Link></li>
                                                        <li><Link to = {"/jobs?title=Infrastructure Engineer"}>Infrastructure Engineer</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">QA Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=QA"}>QA</Link></li>
                                                        <li><Link to = {"/jobs?title=SDET"}>SDET</Link></li>
                                                        <li><Link to = {"/jobs?title=Automation"}>Automation</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Engineer Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Civil Engineer"}>Civil Engineer</Link></li>
                                                        <li><Link to = {"/jobs?title=Software Engineer"}>Software Engineer</Link></li>
                                                        <li><Link to = {"/jobs?title=Electrical Engineer"}>Electrical Engineer</Link></li>
                                                        <li><Link to = {"/jobs?title=Mechanical Engineer"}>Mechanical Engineer</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Attorney Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Legal"}>Legal</Link></li>
                                                        <li><Link to = {"/jobs?title=Lawyer"}>Lawyer</Link></li>
                                                        <li><Link to = {"/jobs?title=Counsel"}>Counsel</Link></li>
                                                        <li><Link to = {"/jobs?title=Attorney"}>Attorney</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Security Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=DevSecOps"}>DevSecOps</Link></li>
                                                        <li><Link to = {"/jobs?title=Cybersecurity"}>Cybersecurity</Link></li>
                                                        <li><Link to = {"/jobs?title=Security Engineer"}>Security Engineer</Link></li>
                                                        <li><Link to = {"/jobs?title=Information Security"}>Information Security</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Operations Jobs</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Auditor"}>Auditor</Link></li>
                                                        <li><Link to = {"/jobs?title=Logistics"}>Logistics</Link></li>
                                                        <li><Link to = {"/jobs?title=Consultant"}>Consultant</Link></li>
                                                        <li><Link to = {"/jobs?title=Supply Chain"}>Supply Chain</Link></li>
                                                        <li><Link to = {"/jobs?title=Operations Manager"}>Operations Manager</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">By Programming Languages</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=C%23"}>C#</Link></li>
                                                        <li><Link to = {"/jobs?title=C%2B%2B"}>C++</Link></li>
                                                        <li><Link to = {"/jobs-php"}>PHP</Link></li>
                                                        <li><Link to = {"/jobs?title=Java%20"}>Java</Link></li>
                                                        <li><Link to = {"/jobs-ruby"}>Ruby</Link></li>
                                                        <li><Link to = {"/jobs-golang"}>Golang</Link></li>
                                                        <li><Link to = {"/jobs-python"}>Python</Link></li>
                                                        <li><Link to = {"/jobs-javascript"}>JavaScript</Link></li>
                                                        <li><Link to = {"/jobs?title=TypeScript"}>TypeScript</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="section-full content-inner jobs-category-bx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">More Jobs and Categories</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs-php"}>PHP Jobs</Link></li>
                                                        <li><Link to = {"/jobs-laravel"}>Laravel Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Symfony&location="}>Symfony Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Wordpress&location="}>Wordpress Jobs</Link></li>
                                                        <li><Link to = {"/jobs-javascript"}>JavaScript Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=TypeScript&location="}>TypeScript Jobs</Link></li>
                                                        <li><Link to = {"/jobs-frontend"}>Frontend Jobs</Link></li>
                                                        <li><Link to = {"/jobs-backend"}>Backend Jobs</Link></li>
                                                        <li><Link to = {"/jobs-fullstack"}>Full Stack Jobs</Link></li>
                                                        <li><Link to = {"/jobs-python"}>Python Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Junior&location="}>Junior Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Part Time&location="}>Part Time Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=DevOps&location="}>DevOps Jobs</Link></li>
                                                        <li><Link to = {"/jobs-marketing"}>Marketing Jobs</Link></li>
                                                        <li><Link to = {"/jobs-sales"}>Sales Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=QA&location="}>QA Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Automation&location="}>Automation Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Product Manager&location="}>Product Manager Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Product Owner&location="}>Product Owner Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Customer Support&location="}>Customer Support Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Copywriter&location="}>Copywriter Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Community Manager&location="}>Community Manager Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Social Media&location="}>Social Media Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Project Manager&location="}>Project Manager Jobs</Link></li>
                                                        <li><Link to = {"/jobs-software-engineer"}>Software Engineer Jobs</Link></li>
                                                        <li><Link to = {"/jobs-customer-success"}>Customer Success Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Machine Learning&location="}>Machine Learning Jobs</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=HR &location="}>HR Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=.NET&location="}>.NET Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Recruiter&location="}>Recruiter Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Talent Acquisition&location="}>Talent Acquisition Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Account Manager&location="}>Account Manager Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Content Writer&location="}>Content Writer Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Data Scientist&location="}>Data Scientist Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Security Engineer&location="}>Security Engineer Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Technical Support&location="}>Technical Support Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=SEO&location="}>SEO Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=UI/UX&location="}>UI/UX Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Node.js&location="}>Node.js Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Tech Lead&location="}>Tech Lead Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Support Engineer&location="}>Support Engineer Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Product Engineer&location="}>Product Engineer Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Web3&location="}>Web3 Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Blockchain&location="}>Blockchain Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Solidity&location="}>Solidity Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Crypto&location="}>Crypto Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title= AI &location="}>AI Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Angular&location="}>Angular Jobs</Link></li>
                                                        <li><Link to = {"/jobs-ruby"}>Ruby Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Ruby on Rails&location="}>Ruby on Rails Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Scala&location="}>Scala Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Site Reliability Engineer&location="}>SRE Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Logistics&location="}>Logistics Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Business Development&location="}>Business Development Jobs</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=iOS&location="}>iOS Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Android&location="}>Android Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Designer&location="}>Designer Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Finance&location="}>Finance Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Mobile&location="}>Mobile Jobs</Link></li>
                                                        <li><Link to = {"/jobs-react"}>React Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Data Analyst&location="}>Data Analyst Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Scrum Master&location="}>Scrum Master Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Java &location="}>Java Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Data Engineer&location="}>Data Engineer Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Product Designer&location="}>Product Designer Jobs</Link></li>
                                                        <li><Link to = {"/jobs-golang"}>Golang Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Salesforce&location="}>Salesforce Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Sales Engineer&location="}>Sales Engineer Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Payroll&location="}>Payroll Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Technical Writer&location="}>Technical Writer Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Accountant&location="}>Accountant Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Platform Engineer&location="}>Platform Engineer Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Business Operations&location="}>Business Operations Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Engineering Manager&location="}>Engineering Manager Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Customer Service&location="}>Customer Service Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Web Developer&location="}>Web Developer Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Freelance&location="}>Freelance Jobs</Link></li>
                                                        <li><Link to = {"/jobs-aws"}>AWS Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Artist&location="}>Artist Jobs</Link></li>
                                                        <li><Link to = {"/jobs-account-executive"}>Account Executive Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=xecutive Assistant&location="}>Executive Assistant Jobs</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default AllJobsByCategory;
