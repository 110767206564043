import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';

const randomNumber = Math.floor(Math.random() * 5) + 10;
const bnr = require('./../../images/banner/bnr' + randomNumber + '.jpg');

class AllJobsByCity extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Explore All Jobs by Popular USA Cities</title>
                    <meta name="description" content="Explore a variety of job opportunities categorized by popular USA cities. Find your next remote work opportunity. #OpenToWorkRemote" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/all-jobs-by-city" />
                </Helmet>

                <div className="page-content">
                    <div className="dez-bnr-inr jobs-category overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <Link to = {"/"}>
                                    <h1 className="text-white">
                                        Browse Job Postings by Popular USA Cities
                                    </h1>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="section-full content-inner jobs-category-bx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">By popular cities</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=Albuquerque">Albuquerque</Link></li>
                                                        <li><Link to="/jobs?title=&location=Atlanta">Atlanta</Link></li>
                                                        <li><Link to="/jobs?title=&location=Austin">Austin</Link></li>
                                                        <li><Link to="/jobs?title=&location=Baltimore">Baltimore</Link></li>
                                                        <li><Link to="/jobs?title=&location=Boston">Boston</Link></li>
                                                        <li><Link to="/jobs?title=&location=Charlotte">Charlotte</Link></li>
                                                        <li><Link to="/jobs?title=&location=Chicago">Chicago</Link></li>
                                                        <li><Link to="/jobs?title=&location=Colorado Spring">Colorado Spring</Link></li>
                                                        <li><Link to="/jobs?title=&location=Cleveland">Cleveland</Link></li>
                                                        <li><Link to="/jobs?title=&location=Columbus">Columbus</Link></li>
                                                        <li><Link to="/jobs?title=&location=Dallas">Dallas</Link></li>
                                                        <li><Link to="/jobs?title=&location=Denver">Denver</Link></li>
                                                        <li><Link to="/jobs?title=&location=Detroit">Detroit</Link></li>
                                                        <li><Link to="/jobs?title=&location=El Paso">El Paso</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=Fort Lauderdale">Fort Lauderdale</Link></li>
                                                        <li><Link to="/jobs?title=&location=Fort Worth">Fort Worth</Link></li>
                                                        <li><Link to="/jobs?title=&location=Fresno">Fresno</Link></li>
                                                        <li><Link to="/jobs?title=&location=Houston">Houston</Link></li>
                                                        <li><Link to="/jobs?title=&location=Indianapolis">Indianapolis</Link></li>
                                                        <li><Link to="/jobs?title=&location=Jacksonville">Jacksonville</Link></li>
                                                        <li><Link to="/jobs?title=&location=Kansas City">Kansas City</Link></li>
                                                        <li><Link to="/jobs?title=&location=Las Vegas">Las Vegas</Link></li>
                                                        <li><Link to="/jobs?title=&location=Long Beach">Long Beach</Link></li>
                                                        <li><Link to="/jobs?title=&location=Los Angeles">Los Angeles</Link></li>
                                                        <li><Link to="/jobs?title=&location=Louisville">Louisville</Link></li>
                                                        <li><Link to="/jobs?title=&location=Memphis">Memphis</Link></li>
                                                        <li><Link to="/jobs?title=&location=Mesa">Mesa</Link></li>
                                                        <li><Link to="/jobs?title=&location=Miami">Miami</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=Milwaukee">Milwaukee</Link></li>
                                                        <li><Link to="/jobs?title=&location=Minneapolis">Minneapolis</Link></li>
                                                        <li><Link to="/jobs?title=&location=Minnesota">Minnesota</Link></li>
                                                        <li><Link to="/jobs?title=&location=Nashville">Nashville</Link></li>
                                                        <li><Link to="/jobs?title=&location=New Orleans">New Orleans</Link></li>
                                                        <li><Link to="/jobs?title=&location=New York">New York</Link></li>
                                                        <li><Link to="/jobs?title=&location=Oakland">Oakland</Link></li>
                                                        <li><Link to="/jobs?title=&location=Oklahoma City">Oklahoma City</Link></li>
                                                        <li><Link to="/jobs?title=&location=Omaha">Omaha</Link></li>
                                                        <li><Link to="/jobs?title=&location=Orlando">Orlando</Link></li>
                                                        <li><Link to="/jobs?title=&location=Philadelphia">Philadelphia</Link></li>
                                                        <li><Link to="/jobs?title=&location=Phoenix">Phoenix</Link></li>
                                                        <li><Link to="/jobs?title=&location=Pittsburgh">Pittsburgh</Link></li>
                                                        <li><Link to="/jobs?title=&location=Portland">Portland</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=Raleigh">Raleigh</Link></li>
                                                        <li><Link to="/jobs?title=&location=Sacramento">Sacramento</Link></li>
                                                        <li><Link to="/jobs?title=&location=San Antonio">San Antonio</Link></li>
                                                        <li><Link to="/jobs?title=&location=San Diego">San Diego</Link></li>
                                                        <li><Link to="/jobs?title=&location=San Francisco">San Francisco</Link></li>
                                                        <li><Link to="/jobs?title=&location=San Jose">San Jose</Link></li>
                                                        <li><Link to="/jobs?title=&location=Seattle">Seattle</Link></li>
                                                        <li><Link to="/jobs?title=&location=St. Louis">St. Louis</Link></li>
                                                        <li><Link to="/jobs?title=&location=Tampa">Tampa</Link></li>
                                                        <li><Link to="/jobs?title=&location=Tucson">Tucson</Link></li>
                                                        <li><Link to="/jobs?title=&location=Tulsa">Tulsa</Link></li>
                                                        <li><Link to="/jobs?title=&location=Virginia Beach">Virginia Beach</Link></li>
                                                        <li><Link to="/jobs?title=&location=Washington">Washington</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default AllJobsByCity;
