import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Header extends Component{

    render() {
        return (
            <div>
                <header className="site-header mo-left header fullwidth">
                    <div className="sticky-header main-bar-wraper navbar-expand-lg">
                        <div className="main-bar clearfix">
                            <div className="container clearfix">
                                <div className="logo-job">
                                    <a href="/" className="" rel="noopener noreferrer">
                                        <img src={require('./../../images/logo-otwr-scaled.png')} className="logo" alt="Logo Open To Work Remote" height="60" width="60" />
                                    </a>
                                </div>
                                <div className="post-job d-none">
                                    <Link to={"/try-remote-jobs-in-your-inbox"} className="dez-tilte"><button className="site-button radius-l"><span role="img" aria-label="Unlock Now">🔓</span> Unlock All Jobs</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
};

export default Header;
