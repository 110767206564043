import React, { Component } from 'react';
import { Link } from "react-router-dom";

class ThankYou extends Component {
    render() {
        return (
            <div className="page-wraper">
                <div className="page-content">
                    <div className="section-full content-inner-3 bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <br />
                                    <h1 className="text-success">Thank you for posting a featured job!</h1>
                                    <br />
                                    <h6>We are validating your payment and your job description URL. It will be online soon. It is take less than 24 hours. <span role="img" aria-label="Alarm Clock">⏰</span></h6>
                                    <br />
                                    <h6><span role="img" aria-label="Incoming Envelope">📨</span> Contact me if you have any doubt. </h6>
                                    <br />
                                    <h6>Thank you again! <span role="img" aria-label="Hugging Face">🤗</span></h6>
                                    <br />
                                    <Link to = {"/contact"} className="site-button" >Contact Me</Link>
                                    <br />
                                    <br />
                                    <Link to={"/jobs"} className="site-button">Go To Jobs</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ThankYou;
