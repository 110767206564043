import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';

const randomNumber = Math.floor(Math.random() * 5) + 10;
const bnr = require('./../../images/banner/bnr' + randomNumber + '.jpg');

class AllJobsByLocation extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Discover All Jobs by Location</title>
                    <meta name="description" content="Explore a variety of job opportunities categorized by location. Find your next remote work opportunity. #OpenToWorkRemote" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/all-jobs-by-location" />
                </Helmet>

                <div className="page-content">
                    <div className="dez-bnr-inr jobs-category overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <Link to = {"/"}>
                                    <h1 className="text-white">
                                        Browse Job Listings by Country and Location
                                    </h1>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="section-full content-inner jobs-category-bx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 m-b30">&nbsp;</div>
                                    <div className="col-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Explore Jobs By Country</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <ul className="category-list">
                                                        <li><Link to="/remote-jobs-worldwide"><span role="img" aria-label="World flag">🌎</span> Remote Jobs Worldwide</Link></li>
                                                        <li><Link to="/jobs-in-usa"><span role="img" aria-label="USA flag">🇺🇸</span> USA Jobs</Link></li>
                                                        <li><Link to="/jobs-in-argentina"><span role="img" aria-label="Argentina flag">🇦🇷</span> Argentina Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Armenia"><span role="img" aria-label="Armenia flag">🇦🇲</span> Armenia Jobs</Link></li>
                                                        <li><Link to="/jobs-in-australia"><span role="img" aria-label="Australia flag">🇦🇺</span> Australia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Austria"><span role="img" aria-label="Austria flag">🇦🇹</span> Austria Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Belgium"><span role="img" aria-label="Belgium flag">🇧🇪</span> Belgium Jobs</Link></li>
                                                        <li><Link to="/jobs-in-brazil"><span role="img" aria-label="Brazil flag">🇧🇷</span> Brazil Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Bulgaria"><span role="img" aria-label="Bulgaria flag">🇧🇬</span> Bulgaria Jobs</Link></li>
                                                        <li><Link to="/jobs-in-canada"><span role="img" aria-label="Canada flag">🇨🇦</span> Canada Jobs</Link></li>
                                                        <li><Link to="/jobs-in-chile"><span role="img" aria-label="Chile flag">🇨🇱</span> Chile Jobs</Link></li>
                                                        <li><Link to="/jobs-in-colombia"><span role="img" aria-label="Colombia flag">🇨🇴</span> Colombia Jobs</Link></li>
                                                        <li><Link to="/jobs-in-costa-rica"><span role="img" aria-label="Costa Rica flag">🇨🇷</span> Costa Rica Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Croatia"><span role="img" aria-label="Croatia flag">🇭🇷</span> Croatia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Cyprus"><span role="img" aria-label="Cyprus flag">🇨🇾</span> Cyprus Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Czechia"><span role="img" aria-label="Czechia flag">🇨🇿</span> Czechia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Denmark"><span role="img" aria-label="Denmark flag">🇩🇰</span> Denmark Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Ecuador"><span role="img" aria-label="Ecuador flag">🇪🇨</span> Ecuador Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=El Salvador"><span role="img" aria-label="El Salvador flag">🇸🇻</span> El Salvador Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Egypt"><span role="img" aria-label="Egypt flag">🇪🇬</span> Egypt Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Estonia"><span role="img" aria-label="Estonia flag">🇪🇪</span> Estonia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Ethiopia"><span role="img" aria-label="Ethiopia flag">🇪🇹</span> Ethiopia Jobs</Link></li>
                                                        <li><Link to="/jobs-in-europe"><span role="img" aria-label="Europe flag">🇪🇺</span> Europe Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Finland"><span role="img" aria-label="Finland flag">🇫🇮</span> Finland Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=France"><span role="img" aria-label="France flag">🇫🇷</span> France Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Gabon"><span role="img" aria-label="Gabon flag">🇬🇦</span> Gabon Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Georgia"><span role="img" aria-label="Georgia flag">🇬🇪</span> Georgia Jobs</Link></li>
                                                        <li><Link to="/jobs-in-germany"><span role="img" aria-label="Germany flag">🇩🇪</span> Germany Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Greece"><span role="img" aria-label="Greece flag">🇬🇷</span> Greece Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Hungary"><span role="img" aria-label="Hungary flag">🇭🇺</span> Hungary Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=India"><span role="img" aria-label="India flag">🇮🇳</span> India Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Indonesia"><span role="img" aria-label="Indonesia flag">🇮🇩</span> Indonesia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Ireland"><span role="img" aria-label="Ireland flag">🇮🇪</span> Ireland Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Israel"><span role="img" aria-label="Israel flag">🇮🇱</span> Israel Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Italy"><span role="img" aria-label="Italy flag">🇮🇹</span> Italy Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Japan"><span role="img" aria-label="Japan flag">🇯🇵</span> Japan Jobs</Link></li>
                                                        <li><Link to="/jobs-in-latam"><span role="img" aria-label="LATAM flag">🌎</span> LATAM Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Latvia"><span role="img" aria-label="Latvia flag">🇱🇻</span> Latvia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Lebanon"><span role="img" aria-label="Lebanon flag">🇱🇧</span> Lebanon Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Liberia"><span role="img" aria-label="Liberia flag">🇱🇷</span> Liberia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Lithuania"><span role="img" aria-label="Lithuania flag">🇱🇹</span> Lithuania Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Luxembourg"><span role="img" aria-label="Luxembourg flag">🇱🇺</span> Luxembourg Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Malaysia"><span role="img" aria-label="Malaysia flag">🇲🇾</span> Malaysia Jobs</Link></li>
                                                        <li><Link to="/jobs-in-mexico"><span role="img" aria-label="Mexico flag">🇲🇽</span> Mexico Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Morocco"><span role="img" aria-label="Morocco flag">🇲🇦</span> Morocco Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Namibia"><span role="img" aria-label="Namibia flag">🇳🇦</span> Namibia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Netherlands"><span role="img" aria-label="Netherlands flag">🇳🇱</span> Netherlands Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=New Zealand"><span role="img" aria-label="New Zealand flag">🇳🇿</span> New Zealand Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Nicaragua"><span role="img" aria-label="Nicaragua flag">🇳🇮</span> Nicaragua Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Nigeria"><span role="img" aria-label="Nigeria flag">🇳🇬</span> Nigeria Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Norway"><span role="img" aria-label="Norway flag">🇳🇴</span> Norway Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Pakistan"><span role="img" aria-label="Pakistan flag">🇵🇰</span> Pakistan Jobs</Link></li>
                                                        <li><Link to="/jobs-in-peru"><span role="img" aria-label="Peru flag">🇵🇪</span> Peru Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Philippines"><span role="img" aria-label="Philippines flag">🇵🇭</span> Philippines Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Poland"><span role="img" aria-label="Poland flag">🇵🇱</span> Poland Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Portugal"><span role="img" aria-label="Portugal flag">🇵🇹</span> Portugal Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Romania"><span role="img" aria-label="Romania flag">🇷🇴</span> Romania Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Serbia"><span role="img" aria-label="Serbia flag">🇷🇸</span> Serbia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Singapore"><span role="img" aria-label="Singapore flag">🇸🇬</span> Singapore Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Slovakia"><span role="img" aria-label="Slovakia flag">🇸🇰</span> Slovakia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=South Africa"><span role="img" aria-label="South Africa flag">🇿🇦</span> South Africa Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Spain"><span role="img" aria-label="Spain flag">🇪🇸</span> Spain Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Sweden"><span role="img" aria-label="Sweden flag">🇸🇪</span> Sweden Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Switzerland"><span role="img" aria-label="Switzerland flag">🇨🇭</span> Switzerland Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Thailand"><span role="img" aria-label="Thailand flag">🇹🇭</span> Thailand Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Tunisia"><span role="img" aria-label="Tunisia flag">🇹🇳</span> Tunisia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Turkey"><span role="img" aria-label="Turkey flag">🇹🇷</span> Turkey Jobs</Link></li>
                                                        <li><Link to="/jobs-in-united-kingdom"><span role="img" aria-label="United Kingdom flag">🇬🇧</span> United Kingdom Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=USA"><span role="img" aria-label="USA flag">🇺🇸</span> United States Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Ukraine"><span role="img" aria-label="Ukraine flag">🇺🇦</span> Ukraine Jobs</Link></li>
                                                        <li><Link to="/jobs-in-uruguay"><span role="img" aria-label="Uruguay flag">🇺🇾</span> Uruguay Jobs</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="section-full content-inner jobs-category-bx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 m-b30">&nbsp;</div>
                                    <div className="col-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Select a State</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=Alabama">Alabama</Link></li>
                                                        <li><Link to="/jobs?title=&location=Alaska">Alaska</Link></li>
                                                        <li><Link to="/jobs?title=&location=Arizona">Arizona</Link></li>
                                                        <li><Link to="/jobs?title=&location=Arkansas">Arkansas</Link></li>
                                                        <li><Link to="/jobs?title=&location=California">California</Link></li>
                                                        <li><Link to="/jobs?title=&location=Colorado">Colorado</Link></li>
                                                        <li><Link to="/jobs?title=&location=Connecticut">Connecticut</Link></li>
                                                        <li><Link to="/jobs?title=&location=Delaware">Delaware</Link></li>
                                                        <li><Link to="/jobs?title=&location=Florida">Florida</Link></li>
                                                        <li><Link to="/jobs?title=&location=Georgia">Georgia</Link></li>
                                                        <li><Link to="/jobs?title=&location=Hawaii">Hawaii</Link></li>
                                                        <li><Link to="/jobs?title=&location=Idaho">Idaho</Link></li>
                                                        <li><Link to="/jobs?title=&location=Illinois">Illinois</Link></li>
                                                        <li><Link to="/jobs?title=&location=Indiana">Indiana</Link></li>
                                                        <li><Link to="/jobs?title=&location=Iowa">Iowa</Link></li>
                                                        <li><Link to="/jobs?title=&location=Kansas">Kansas</Link></li>
                                                        <li><Link to="/jobs?title=&location=Kentucky">Kentucky</Link></li>
                                                        <li><Link to="/jobs?title=&location=Louisiana">Louisiana</Link></li>
                                                        <li><Link to="/jobs?title=&location=Maine">Maine</Link></li>
                                                        <li><Link to="/jobs?title=&location=Maryland">Maryland</Link></li>
                                                        <li><Link to="/jobs?title=&location=Massachusetts">Massachusetts</Link></li>
                                                        <li><Link to="/jobs?title=&location=Michigan">Michigan</Link></li>
                                                        <li><Link to="/jobs?title=&location=Minnesota">Minnesota</Link></li>
                                                        <li><Link to="/jobs?title=&location=Mississippi">Mississippi</Link></li>
                                                        <li><Link to="/jobs?title=&location=Missouri">Missouri</Link></li>
                                                        <li><Link to="/jobs?title=&location=Montana">Montana</Link></li>
                                                        <li><Link to="/jobs?title=&location=Nebraska">Nebraska</Link></li>
                                                        <li><Link to="/jobs?title=&location=Nevada">Nevada</Link></li>
                                                        <li><Link to="/jobs?title=&location=New Hampshire">New Hampshire</Link></li>
                                                        <li><Link to="/jobs?title=&location=New Jersey">New Jersey</Link></li>
                                                        <li><Link to="/jobs?title=&location=New Mexico">New Mexico</Link></li>
                                                        <li><Link to="/jobs?title=&location=New York">New York</Link></li>
                                                        <li><Link to="/jobs?title=&location=North Carolina">North Carolina</Link></li>
                                                        <li><Link to="/jobs?title=&location=North Dakota">North Dakota</Link></li>
                                                        <li><Link to="/jobs?title=&location=Ohio">Ohio</Link></li>
                                                        <li><Link to="/jobs?title=&location=Oklahoma">Oklahoma</Link></li>
                                                        <li><Link to="/jobs?title=&location=Oregon">Oregon</Link></li>
                                                        <li><Link to="/jobs?title=&location=Pennsylvania">Pennsylvania</Link></li>
                                                        <li><Link to="/jobs?title=&location=Rhode Island">Rhode Island</Link></li>
                                                        <li><Link to="/jobs?title=&location=South Carolina">South Carolina</Link></li>
                                                        <li><Link to="/jobs?title=&location=South Dakota">South Dakota</Link></li>
                                                        <li><Link to="/jobs?title=&location=Tennessee">Tennessee</Link></li>
                                                        <li><Link to="/jobs?title=&location=Texas">Texas</Link></li>
                                                        <li><Link to="/jobs?title=&location=Utah">Utah</Link></li>
                                                        <li><Link to="/jobs?title=&location=Vermont">Vermont</Link></li>
                                                        <li><Link to="/jobs?title=&location=Virginia">Virginia</Link></li>
                                                        <li><Link to="/jobs?title=&location=Washington">Washington</Link></li>
                                                        <li><Link to="/jobs?title=&location=West Virginia">West Virginia</Link></li>
                                                        <li><Link to="/jobs?title=&location=Wisconsin">Wisconsin</Link></li>
                                                        <li><Link to="/jobs?title=&location=Wyoming">Wyoming</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="section-full content-inner jobs-category-bx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 m-b30">&nbsp;</div>
                                    <div className="col-lg-4 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">By popular cities</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=Albuquerque">Albuquerque</Link></li>
                                                        <li><Link to="/jobs?title=&location=Atlanta">Atlanta</Link></li>
                                                        <li><Link to="/jobs?title=&location=Austin">Austin</Link></li>
                                                        <li><Link to="/jobs?title=&location=Baltimore">Baltimore</Link></li>
                                                        <li><Link to="/jobs?title=&location=Boston">Boston</Link></li>
                                                        <li><Link to="/jobs?title=&location=Charlotte">Charlotte</Link></li>
                                                        <li><Link to="/jobs?title=&location=Chicago">Chicago</Link></li>
                                                        <li><Link to="/jobs?title=&location=Colorado Spring">Colorado Spring</Link></li>
                                                        <li><Link to="/jobs?title=&location=Cleveland">Cleveland</Link></li>
                                                        <li><Link to="/jobs?title=&location=Columbus">Columbus</Link></li>
                                                        <li><Link to="/jobs?title=&location=Dallas">Dallas</Link></li>
                                                        <li><Link to="/jobs?title=&location=Denver">Denver</Link></li>
                                                        <li><Link to="/jobs?title=&location=Detroit">Detroit</Link></li>
                                                        <li><Link to="/jobs?title=&location=El Paso">El Paso</Link></li>
                                                        <li><Link to="/jobs?title=&location=Fort Lauderdale">Fort Lauderdale</Link></li>
                                                        <li><Link to="/jobs?title=&location=Fort Worth">Fort Worth</Link></li>
                                                        <li><Link to="/jobs?title=&location=Fresno">Fresno</Link></li>
                                                        <li><Link to="/jobs?title=&location=Houston">Houston</Link></li>
                                                        <li><Link to="/jobs?title=&location=Indianapolis">Indianapolis</Link></li>
                                                        <li><Link to="/jobs?title=&location=Jacksonville">Jacksonville</Link></li>
                                                        <li><Link to="/jobs?title=&location=Kansas City">Kansas City</Link></li>
                                                        <li><Link to="/jobs?title=&location=Las Vegas">Las Vegas</Link></li>
                                                        <li><Link to="/jobs?title=&location=Long Beach">Long Beach</Link></li>
                                                        <li><Link to="/jobs?title=&location=Los Angeles">Los Angeles</Link></li>
                                                        <li><Link to="/jobs?title=&location=Louisville">Louisville</Link></li>
                                                        <li><Link to="/jobs?title=&location=Memphis">Memphis</Link></li>
                                                        <li><Link to="/jobs?title=&location=Mesa">Mesa</Link></li>
                                                        <li><Link to="/jobs?title=&location=Miami">Miami</Link></li>
                                                        <li><Link to="/jobs?title=&location=Milwaukee">Milwaukee</Link></li>
                                                        <li><Link to="/jobs?title=&location=Minneapolis">Minneapolis</Link></li>
                                                        <li><Link to="/jobs?title=&location=Minnesota">Minnesota</Link></li>
                                                        <li><Link to="/jobs?title=&location=Nashville">Nashville</Link></li>
                                                        <li><Link to="/jobs?title=&location=New Orleans">New Orleans</Link></li>
                                                        <li><Link to="/jobs?title=&location=New York">New York</Link></li>
                                                        <li><Link to="/jobs?title=&location=Oakland">Oakland</Link></li>
                                                        <li><Link to="/jobs?title=&location=Oklahoma City">Oklahoma City</Link></li>
                                                        <li><Link to="/jobs?title=&location=Omaha">Omaha</Link></li>
                                                        <li><Link to="/jobs?title=&location=Orlando">Orlando</Link></li>
                                                        <li><Link to="/jobs?title=&location=Philadelphia">Philadelphia</Link></li>
                                                        <li><Link to="/jobs?title=&location=Phoenix">Phoenix</Link></li>
                                                        <li><Link to="/jobs?title=&location=Pittsburgh">Pittsburgh</Link></li>
                                                        <li><Link to="/jobs?title=&location=Portland">Portland</Link></li>
                                                        <li><Link to="/jobs?title=&location=Raleigh">Raleigh</Link></li>
                                                        <li><Link to="/jobs?title=&location=Sacramento">Sacramento</Link></li>
                                                        <li><Link to="/jobs?title=&location=San Antonio">San Antonio</Link></li>
                                                        <li><Link to="/jobs?title=&location=San Diego">San Diego</Link></li>
                                                        <li><Link to="/jobs?title=&location=San Francisco">San Francisco</Link></li>
                                                        <li><Link to="/jobs?title=&location=San Jose">San Jose</Link></li>
                                                        <li><Link to="/jobs?title=&location=Seattle">Seattle</Link></li>
                                                        <li><Link to="/jobs?title=&location=St. Louis">St. Louis</Link></li>
                                                        <li><Link to="/jobs?title=&location=Tampa">Tampa</Link></li>
                                                        <li><Link to="/jobs?title=&location=Tucson">Tucson</Link></li>
                                                        <li><Link to="/jobs?title=&location=Tulsa">Tulsa</Link></li>
                                                        <li><Link to="/jobs?title=&location=Virginia Beach">Virginia Beach</Link></li>
                                                        <li><Link to="/jobs?title=&location=Washington">Washington</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default AllJobsByLocation;
