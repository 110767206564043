import React from 'react';
import { Link } from "react-router-dom";

const TitleJobs = ({ title }) => {

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
    ];
    const today = new Date();
    const currentMonth = months[today.getMonth()];
    const year = today.getFullYear();
    const formattedDate = `${currentMonth} ${year}`;

    return (
        <div>
            <div className="page-content bg-white">
                <div className="dez-bnr-inr bg-dark">
                    <div className="container">
                        <div className="dez-bnr-inr-entry">
                            <Link to={""}>
                                <h1 className="text-white">
                                    {title}
                                </h1>
                            </Link>
                            <div className="breadcrumb-row">
                                <ul className="">
                                    <li>Spot your dream job today on #OpenToWorkRemote</li>
                                </ul>
                            </div>
                            <br />
                            <h6 className="text-white">
                                Last Updated: {formattedDate}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TitleJobs;
