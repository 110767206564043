import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../../Layout/Header';
import Footer from './../../Layout/Footer2';
import { Helmet } from 'react-helmet';

const randomNumber = Math.floor(Math.random() * 5) + 10;
const bnr = require('./../../../images/banner/bnr' + randomNumber + '.jpg');

const post1image = require('./../../../images/blog/default/cover.png');

class Blog extends Component {
    render() {
        return (
            <div>
                <Header />

                <Helmet>
                    <title>Welcome To My Remote Work Blog: Insights, Tips, and Jobs.</title>
                    <meta name="description" content="Explore a wealth of insights and tips on thriving in the world of remote work. Open up to new opportunities and enhance your remote work experience. Join us." />
                    <link rel="canonical" href="https://www.opentoworkremote.com/blog" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="dez-bnr-inr overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <h1 className="text-white">Open To Work Remote: Blog</h1>
                            </div>
                        </div>
                    </div>

                    <div className="content-area">
                        <div className="container">

                            <div className="dez-blog-grid-3 row" id="masonry" >
                                <div className="post card-container col-lg-4 col-md-6 col-sm-6" key="">
                                    <div className="blog-post blog-grid blog-style-1">

                                    </div>
                                </div>

                                <div className="post card-container col-lg-4 col-md-6 col-sm-6">
                                    <div className="blog-post blog-grid blog-style-1">
                                        <div className="dez-post-media dez-img-effect radius-sm"> 
                                            <Link to={"/blog/building-tools-to-support-remote-work"}><img src={post1image} alt="" /></Link> 
                                        </div>
                                        <div className="dez-info">
                                            <div className="dez-post-meta">
                                                <ul className="d-flex align-items-center">
                                                    <li className="post-date"><i className="fa fa-calendar"></i>April 12, 2024</li>
                                                </ul>
                                            </div>
                                            <div className="dez-post-title">
                                                <h5 className="post-title font-20"><Link to={"/blog/building-tools-to-support-remote-work"}>Mini Tools & About Me</Link></h5>
                                            </div>
                                            <div className="dez-post-text">
                                                <p>I'm building a series of tools to support remote work and help people find jobs.</p>
                                            </div>
                                            <br />
                                            <div className="dez-post-readmore blog-share text-center"> 
                                                <Link to={"/blog/building-tools-to-support-remote-work"} className="site-button radius-l button-md"><span className="fw6">READ MORE</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><hr /><br />

                            <div className="dez-blog-grid-3 row" id="masonry" >
                                <div className="post card-container col-lg-4 col-md-6 col-sm-6" key="">
                                    <div className="blog-post blog-grid blog-style-1">

                                    </div>
                                </div>

                                <div className="post card-container col-lg-4 col-md-6 col-sm-6" style={{display: "block"}}>
                                    <div className="blog-post blog-grid blog-style-1">
                                        <div className="dez-post-media dez-img-effect radius-sm"> 
                                            
                                        </div>
                                        <div className="dez-info">
                                            <div className="dez-post-meta">
                                                <ul className="d-flex align-items-center">
                                                    <li className="post-date"><i className="fa fa-calendar"></i>December 03, 2023</li>
                                                </ul>
                                            </div>
                                            <div className="dez-post-title">
                                                <h5 className="post-title font-20"><Link to={"/blog/embracing-remote-work"}>Embracing the Remote Revolution</Link></h5>
                                            </div>
                                            <div className="dez-post-text">
                                                <p>Welcome to the era of remote work, where the traditional office is transforming into a virtual playground of productivity.</p>
                                            </div>
                                            <br />
                                            <div className="dez-post-readmore blog-share text-center"> 
                                                <Link to={"/blog/embracing-remote-work"} className="site-button radius-l button-md"><span className="fw6">READ MORE</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><hr /><br />

                            <div className="dez-blog-grid-3 row" id="masonry" >
                                <div className="post card-container col-lg-4 col-md-6 col-sm-6" key="">
                                    <div className="blog-post blog-grid blog-style-1">

                                    </div>
                                </div>

                                <div className="post card-container col-lg-4 col-md-6 col-sm-6" style={{display: "block"}}>
                                    <div className="blog-post blog-grid blog-style-1">
                                        <div className="dez-post-media dez-img-effect radius-sm"> 
                                            
                                        </div>
                                        <div className="dez-info">
                                            <div className="dez-post-meta">
                                                <ul className="d-flex align-items-center">
                                                    <li className="post-date"><i className="fa fa-calendar"></i>October 18, 2023</li>
                                                </ul>
                                            </div>
                                            <div className="dez-post-title">
                                                <h5 className="post-title font-20"><Link to={"/blog/remote-work-productivity-tips"}>Remote work productivity tips</Link></h5>
                                            </div>
                                            <div className="dez-post-text">
                                                <p>Remote work presents various opportunities for people to work and live more flexibly. By following these tips and organizing your workspace and routine, remote work productivity can be increased.</p>
                                            </div>
                                            <br />
                                            <div className="dez-post-readmore blog-share text-center"> 
                                                <Link to={"/blog/remote-work-productivity-tips"} className="site-button radius-l button-md"><span className="fw6">READ MORE</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default Blog;
