
function getJobs(title, location, source, company) {
    return {
        method: "GET",
        url: getUrl(),
        params: {
            perPage: 20,
            page: 1,
            title: title,
            location: location,
            salaryMin: null,
            tag: null,
            company: company,
            source: source,
        },
        headers: getHeaders(),
    };
};

function getUrl() {
    let url = 'http://localhost:8080/jobs';

    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
        url = 'https://open-to-work-remote-api.p.rapidapi.com/jobs';
    }

    return url;
};

function getHeaders() {
    let headers = {};

    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
        headers = {
            'X-RapidAPI-Key': '61c95e0e86msh784d6d5dfb4761dp1ed5e9jsn7923c8051dc2',
            'X-RapidAPI-Host': 'open-to-work-remote-api.p.rapidapi.com',
            'X-Source': 'OpenToWorkRemote.com',
        };
    }

    return headers;
};

module.exports = {
    getJobs
};
