import React, { useEffect } from 'react';

const Inbox = () => {
    useEffect(() => {
        const url = 'https://maurobonfietti.gumroad.com/l/remote-jobs-in-your-inbox-7-days-free';
        window.location.href = url;
    }, []);

    return (
        <div className="page-wraper">
            <div className="page-content">
                <div className="section-full content-inner-3 bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h1 className="text-success">Redirection To Remote Jobs In Your Inbox...</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Inbox;
