import React, { Component } from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';

class Sponsors extends Component {
    render() {
        return (
            <div>
                <Header />

                <Helmet>
                    <title>The sponsors for Open To Work Remote</title>
                    <meta name="description" content="Discover and appreciate the sponsors who contribute to the success of Open To Work Remote. Learn more about the companies supporting our mission." />
                    <link rel="canonical" href="https://www.opentoworkremote.com/sponsors" />
                </Helmet>

                <div className="page-content bg-white">

                    <div className="dez-bnr-inr bg-dark" style={{height: "100px"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <h1 className="text-white" style={{margin: "25px"}}>Sponsors for Open To Work Remote</h1>
                            </div>
                        </div>
                    </div>

                    <div className="content-area">
                        <div className="container">
                            <div className="dez-blog-grid-3 row" id="masonry" >
                                <div className="post card-container col-lg-4 col-md-6 col-sm-6" key="">
                                    <div className="blog-post blog-grid blog-style-1">
                                    </div>
                                </div>
                                <div className="post card-container col-lg-4 col-md-6 col-sm-6">
                                    <div className="blog-post blog-grid blog-style-1">
                                        <div className="dez-post-title">
                                            <h5 className="post-title font-20"><a href="https://www.somoslingua.com.ar/?ref=opentoworkremote.com&utm_source=opentoworkremote.com" target="_blank" rel="noopener noreferrer sponsored">Sponsor: Somos Lingua</a></h5>
                                        </div>
                                        <div className="dez-post-media dez-img-effect radius-sm">
                                            <a href="https://www.somoslingua.com.ar/?ref=opentoworkremote.com&utm_source=opentoworkremote.com" target="_blank" rel="noopener noreferrer sponsored"><img src="https://www.somoslingua.com.ar/wp-content/uploads/2021/10/profesional-training.png" alt="Somos Lingua Logo" /></a>
                                        </div>
                                        <div className="dez-info">
                                            <div className="dez-post-meta">

                                            </div>
                                            <div className="dez-post-title">
                                                <h5 className="post-title font-20"><a href="https://www.somoslingua.com.ar/?ref=opentoworkremote.com&utm_source=opentoworkremote.com" target="_blank" rel="noopener noreferrer sponsored">PROFESSIONAL ENGLISH LEVEL ASSESSMENT</a></h5>
                                            </div>
                                            <div className="dez-post-text">
                                                <p>We evaluate the level of English of your candidates and collaborators in just four steps.
                                                    You can select the test that best suits your needs as a company.
                                                    At Somos Lingua, we take care of 100% of the evaluation process and send you the report and results via email. We want to provide companies
                                                    and recruiters a solution adapted to the real needs of the market.</p>
                                            </div>
                                            <br />
                                            <div className="dez-post-readmore blog-share">
                                                <a href="https://www.somoslingua.com.ar/test-de-nivel/?ref=opentoworkremote.com&utm_source=opentoworkremote.com" className="site-button radius-l button-lg" target="_blank" rel="noopener noreferrer sponsored" style={{width: "100%"}} >Test Your English</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br /><br />

                            <div className="dez-blog-grid-3 row" id="masonry" >
                                <div className="post card-container col-lg-4 col-md-6 col-sm-6" key="">
                                    <div className="blog-post blog-grid blog-style-1">
                                    </div>
                                </div>
                                <div className="post card-container col-lg-4 col-md-6 col-sm-6">
                                    <div className="section-full content-inner-2 call-to-action overlay-black-dark text-white text-center bg-img-fix">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h2 className="m-b10">Ready to be Sponsor?</h2>
                                                    <div className="dez-post-text">
                                                        <br />
                                                        <p>Subscribe and Get One Free Job Posting!</p>
                                                        <br />
                                                    </div>
                                                    <a href="https://maurobonfietti.gumroad.com/l/EarlySponsorship?ref=opentoworkremote.com&utm_source=opentoworkremote.com" className="site-button" target="_blank" rel="noopener noreferrer" style={{width: "100%"}} >Early Sponsorship</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dez-blog-grid-3 row" id="masonry" >
                                <div className="post card-container col-lg-4 col-md-6 col-sm-6" key="">
                                    <div className="blog-post blog-grid blog-style-1"></div>
                                </div>
                                <div className="post card-container col-lg-4 col-md-6 col-sm-6">
                                    <div className="blog-post blog-grid blog-style-1"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <Footer />
            </div>
        );
    }
};

export default Sponsors;
