import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import {
PayPalScriptProvider,
        PayPalButtons
} from "@paypal/react-paypal-js";
var bnr3 = require('./../../images/lines.png');
const ButtonWrapper = ({ showSpinner }) => {

    var jobUrl;
    function changeHandler(e) {
        jobUrl = e.target.value;
        console.log('jobUrl:', jobUrl);
    }

    return (
    <div>
    
        <Helmet>
            <title>Post your Job in our website</title>
            <meta name="description" content="Post your job in our website and connect with top talent. Find the perfect candidate for your remote job opening." />
            <link rel="canonical" href="https://www.opentoworkremote.com/post" />
        </Helmet>
    
        <div>
            <br />
            Enter your Job Description URL:
            <br />
            <input type="text" value={jobUrl} onChange={changeHandler}  maxLength="119" style={{width: "100%"}}></input>
            <br />
            <i>It will upload all the info for you.</i>
            <br />
            <br />
        </div>
        <PayPalButtons
            style={{"layout": "vertical"}}
            disabled={false}
            forceReRender={['99', 'USD', {"layout": "vertical"}]}
            fundingSource={undefined}
            createOrder={(data, actions) => {
                        return actions.order
                                .create({
                                    purchase_units: [
                                        {
                                            description: "Url: " + jobUrl,
                                            amount: {
                                                currency_code: 'USD',
                                                value: '99',
                                            },
                                        },
                                    ],
                                    application_context: {
                                        shipping_preference: "NO_SHIPPING",
                                    },
                                })
                                .then((orderId) => {
                                    // Your code here after create the order
                                    console.log('orderId:', orderId);
                                    console.log('jobUrl:', jobUrl);
                                    return orderId;
                                });
                    }}
            onApprove={function (data, actions) {
                        return actions.order.capture().then(function () {
                            console.log('data.orderID:', data.orderID);
                            alert('Payment Approved. Your Order ID: ' + data.orderID + '. Thank you for posting a featured job!');
                            window.location.replace("https://opentoworkremote.com/thankyou");
                        });
                    }}
            />
        <br />
        <h6><span role="img" aria-label="Incoming Envelope">📨</span> Contact me if you prefer other payment options or have any doubts. </h6>
        <br />
        <Link to = {"/contact"} className="site-button" style={{width: "100%"}}>Contact Me</Link>
        <br />
        <br />
        <Link to={"/jobs"} className="site-button" style={{width: "100%"}}>Back To Jobs</Link>
    </div>
    );
}

class PostJob extends Component {

    render() {

        var clientId = '';
        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            clientId = 'Adi8z6FitgmQGMnxCj7p6yfP9zbx1eQ-uUK4bRW9f-6_rNLJifPTSYNW5JQlivhC0b44kzZmYtDijtFr';
            console.log('dev env.');
        } else {
            clientId = 'AQ1pm_uQUunqzX_10S63x6ijyqCMgE9tZSusr7X_h2_H-FZuyyXJdJ44190EkbuTEb9et3JGiAzugwxM';
            console.log('prod env.');
        }

        return (
            <div className="page-wraper">
                <div className="page-content">

                    <div className="section-full content-inner-2 overlay-white-middle" style={{backgroundImage: "url( " + bnr3 + ")", backgroundPosition: "bottom", backgroundRepeat: "no-repeat", backgroundSize: "100%"}}>
                        <div className="container">
                            <div className="section-head text-black text-center">
                                <h1 className="m-b0">Post a featured job</h1>
                            </div>

                            <div className="section-content box-sort-in button-example m-t80">
                                <div className="pricingtable-row">
                                    <div className="row max-w1000 m-auto">

                                        <div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
                                        </div>
                                        <div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
                                            <div className="pricingtable-wrapper style2 bg-primary text-white active">
                                                <div className="pricingtable-inner">
                                                    <div className="pricingtable-price"> 
                                                        <h4 className="font-weight-300 m-t10 m-b0">Professional</h4>
                                                        <div className="pricingtable-bx"> $ <span>99</span> /  per 30 days </div>
                                                    </div>
                                                    <p>Post a featured job per 30 days.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="section-content box-sort-in button-example m-t80">

                                <div className="row max-w1000 m-auto">
                                    <div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
                                        <h2 className="m-b0">Pay with PayPal</h2>

                                        <div style={{maxWidth: "750px", minHeight: "200px"}}>
                                            <PayPalScriptProvider
                                                options={{
                                                        "client-id": clientId,
                                                        components: "buttons",
                                                        currency: "USD",
                                                        locale: "en_US"
                                                    }}
                                                >
                                                <ButtonWrapper
                                                    showSpinner={false}
                                                    />
                                            </PayPalScriptProvider>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            );
    }
};

export default PostJob;
