import React from 'react';
import { Link } from "react-router-dom";
import LogoComponent from './../Element/LogoComponent';

const ContentJobs = ({ jobs, total, page, pages, prevPage, nextPage }) => {

    const totalJobs = total + " Jobs Found";

    if (page > pages) {
        page = pages;
    }

    const myparams = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    return (
        <div>
            <div className="content-block">
               <div className="section-full bg-white browse-job p-b50">
                  <div className="container">
                     <div className="job-bx-title clearfix">
                        <h5 className="font-weight-700 pull-left text-uppercase">
                           {totalJobs}
                        </h5>
                        <div className="float-right">
                        </div>
                     </div>
                     {total === 0 && (
                     <div className="">
                        <h2 className="m-b5">Oops! No Jobs Found.</h2>
                        <br />
                        <h5 className="fw4">It looks like there are no jobs matching your filters.</h5>
                        <h5 className="fw4">Don't worry though, keep exploring and adjusting your search criteria for better results.</h5>
                        {myparams.title && myparams.location && (
                        <div>
                           <a href={"?title=" + myparams.title} className="dez-tilte">More Jobs in: {myparams.title}</a>
                           <br />
                           <a href={"?location=" + myparams.location} className="dez-tilte">More Jobs on: {myparams.location}</a>
                           <br />
                        </div>
                        )}
                        <Link to={"/all-jobs-by-category"} className="dez-tilte">
                        Explore All Jobs By Category</Link>
                        <br />
                        <Link to={"/all-jobs-by-location"} className="dez-tilte">
                        Explore All Jobs By Location</Link>
                     </div>
                     )}
                     <ul className="post-job-bx browse-job-grid row">
                        {jobs.map((item, index) => (
                        <li className={(item.featured ? "col-lg-6" : "col-lg-6")} key={index} >
                        <Link to={`/view/${item.id}`} >
                        <div className={"post-bx " + (item.featured ? "featured" : "") }>
                        <div className="d-flex m-b30">
                           <div className="job-post-company">
                              <span style={{display: "none"}}>
                              <img src={ (item.logo ? item.logo : require('./../../images/logo-otwr-scaled.png'))}
                              alt="Company Logo"
                              height="60"
                              width="60"
                              />
                              </span>
                           </div>
                           <div className="job-post-info">
                              <span>
                                 <LogoComponent logoUrl={item.logo} />
                              </span>
                              <br /><br />
                              <h3>
                                 {" "}
                                 {item["company"]}
                              </h3>
                              <h4>
                                 {" "}
                                 {item["title"]}
                              </h4>
                              {item.salaryRange && (
                              <ul>
                                 <li>
                                    <i className="fa fa-money"></i>{" "}
                                    Salary:{" "}
                                    {
                                    item[
                                    "salaryRange"
                                    ]
                                    }
                                    .{" "}
                                 </li>
                              </ul>
                              )}
                              {item.location && (
                              <ul>
                                 <li>
                                    <i className="fa fa-map-marker"></i>{" "}
                                    Location:{" "}
                                    {
                                    item[
                                    "location"
                                    ]
                                    }
                                    .{" "}
                                 </li>
                              </ul>
                              )}
                              <ul>
                                 <li>
                                    <i className="fa fa-clock-o"></i>{" "}
                                    Published:{" "}
                                    {
                                    item[
                                    "publicationDate2"
                                    ]
                                    }
                                    .
                                 </li>
                              </ul>
                              <ul>
                                 <li>
                                    <i className="fa fa-window-restore"></i>{" "}
                                    Source:{" "}
                                    {item["source"]}
                                    .
                                 </li>
                              </ul>
                              <ul>
                                 {item.tags > "" && (
                                 <li>
                                    <i className="fa fa-tags"></i>{" "}
                                    Tags:{" "}
                                    {item.tags
                                    .slice(
                                    0,
                                    4
                                    )
                                    .join(
                                    ", "
                                    )}
                                    .{" "}
                                 </li>
                                 )}
                              </ul>
                           </div>
                        </div>
                  </div>
                  </Link>
                  </li>
                  ))}
                  </ul>
                  <div className="pagination-bx m-t30">
                     <ul className="pagination">
                        {prevPage && (
                        <li className="prev">
                           <a href={`${prevPage}`}>
                           <i className="ti-arrow-left"></i>{" "}
                           Prev
                           </a>
                        </li>
                        )}
                        {(page !== 0 && pages !== 0) && page && (
                        <li className="active">
                           &nbsp; Page {page} &nbsp; / &nbsp;{" "}
                           {pages} &nbsp;
                        </li>
                        )}
                        {nextPage && (
                        <li className="next">
                           <a href={`${nextPage}`}>
                           Next{" "}
                           <i className="ti-arrow-right"></i>
                           </a>
                        </li>
                        )}
                     </ul>
                  </div>
               </div>
            </div>
         </div>
        </div>
    );
};

export default ContentJobs;
