import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';

class Error404 extends Component {
    render() {
        return (
            <div className="page-wraper">

                <Header />

                <div className="page-content">
                    <div className="section-full content-inner-3 bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 error-page text-center">
                                    <h1 className="text-black">Error 404</h1>
                                    <h2 className="dz_error text-secondry">404</h2>
                                    <h3>OOPS!</h3>
                                    <h4 className="text-primary">Page Not Found</h4>
                                    <Link to={"/"} className="site-button radius-l button-lg">Back To Home Page</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
        );
    }
};
export default Error404;
