import React, { useEffect } from 'react';

const Tools = () => {
    useEffect(() => {
        const url = 'https://tools.opentoworkremote.com';
        window.location.href = url;
    }, []);

    return (
        <div className="page-wraper">
            <div className="page-content">
                <div className="section-full content-inner-3 bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h1 className="text-success">Redirection To Tools...</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tools;
