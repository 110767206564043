import React from 'react';
import fallbackLogo from './../../images/logo-otwr-scaled.png';

const LogoComponent = ({ logoUrl }) => {
    const onError = (event) => {
        event.target.src = fallbackLogo;
    };

    return (
        <img
            src={logoUrl || fallbackLogo}
            alt="Company Logo"
            width="60"
            height="60"
            onError={onError}
        />
    );
};

export default LogoComponent;
